import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Importing Delete icon
import { toast } from "react-toastify";

const GroupForm = ({ groupId, onSaveSuccess, onCancel }) => {
	const [group, setGroup] = useState({
		group_name: "",
		group_desc: "",
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Simple validation state for the group_name
	const [errors, setErrors] = useState({
		group_name: "",
	});

	useEffect(() => {
		if (groupId === null || groupId === "new") {
			setGroup({
				group_name: "",
				group_desc: "",
			});
		} else {
			fetch(`https://nrgwappdata.com/appGroupFetch/${groupId}`)
				.then((res) => res.json())
				.then((groupData) => {
					setGroup(groupData);
				})
				.catch((error) => {
					console.error("Failed to fetch group details", error);
					toast.error("Failed to load group details for editing.");
				});
		}
	}, [groupId]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setGroup((prevGroup) => ({
			...prevGroup,
			[name]: value,
		}));

		// Clear validation error for group_name if it's being edited
		if (name === "group_name" && errors.group_name) {
			setErrors({ ...errors, group_name: "" });
		}
	};
	const handleDeleteGroup = () => {
		//event.preventDefault();
		if (window.confirm("Are you sure you want to delete this group?")) {
			setIsSubmitting(true);
			fetch(`https://nrgwappdata.com/appGroupsDelete/${groupId}`, {
				method: "DELETE",
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.json();
				})
				.then(() => {
					toast.success("Group deleted successfully.");
					onSaveSuccess(); // You might need to adjust how your UI responds here
				})
				.catch((error) => {
					console.error("Error deleting group", error);
					toast.error("Failed to delete the group.");
				})
				.finally(() => setIsSubmitting(false));
		}
	};
	const validateForm = () => {
		if (!group.group_name.trim()) {
			setErrors({ ...errors, group_name: "Group Name is required." });
			return false; // Form is not valid
		}
		return true; // Form is valid
	};

	const handleCreateGroup = () => {
		if (!validateForm()) return; // Stop if validation fails

		setIsSubmitting(true);
		const url = `https://nrgwappdata.com/appGroupsNew`;

		fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(group),
		})
			.then((response) => {
				if (!response.ok) throw new Error("Network response was not ok");
				return response.json();
			})
			.then(() => {
				toast.success("Group added successfully.");
				onSaveSuccess();
			})
			.catch((error) => {
				console.error("Error saving group", error);
				toast.error("Failed to save the group.");
			})
			.finally(() => setIsSubmitting(false));
	};

	const handleUpdateGroup = () => {
		if (!validateForm()) return; // Stop if validation fails

		setIsSubmitting(true);
		const url = `https://nrgwappdata.com/appGroupUpdate/${groupId}`;

		fetch(url, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(group),
		})
			.then((response) => {
				if (!response.ok) {
					// Log or inspect the response to see more details
					console.log(response.status);
					return response.text().then((text) => {
						throw new Error(`Network response was not ok: ${text}`);
					});
				}
				return response.json();
			})
			.then(() => {
				toast.success("Group updated successfully.");
				onSaveSuccess();
			})
			.catch((error) => {
				console.error("Error updating group", error);
				// Update toast to show error message for better debugging
				toast.error(`Failed to update the group: ${error.message}`);
			})
			.finally(() => setIsSubmitting(false));
	};

	// Adjusted dispatcher to handle "new" or a number for groupId
	const handleSubmit = (event) => {
		event.preventDefault();
		groupId === "new" ? handleCreateGroup() : handleUpdateGroup();
	};

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			onSubmit={handleSubmit}
			sx={{ mt: 1 }}
		>
			<Typography variant="h6">
				{groupId === "new" ? "Add Group" : "Edit Group"}
			</Typography>
			<TextField
				margin="normal"
				required
				fullWidth
				id="group_name"
				label="Group Name"
				name="group_name"
				value={group.group_name}
				onChange={handleChange}
				error={!!errors.group_name}
				helperText={errors.group_name}
			/>
			<TextField
				margin="normal"
				fullWidth
				id="group_desc"
				label="Group Description"
				name="group_desc"
				multiline
				rows={4}
				value={group.group_desc}
				onChange={handleChange}
			/>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				disabled={isSubmitting}
				sx={{ mt: 3, mb: 2 }}
			>
				{groupId === "new" ? "Save New Group" : "Update Group"}
			</Button>
			<Button
				fullWidth
				variant="outlined"
				onClick={onCancel}
				disabled={isSubmitting}
				sx={{ mt: 1, mb: 2 }}
			>
				Cancel
			</Button>
		</Box>
	);
};

export default GroupForm;
