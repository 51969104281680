import React, { useState, useEffect } from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import MuiAlert from "@mui/material/Alert";

const MessageList = () => {
	const [messages, setMessages] = useState([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [currentMessageId, setCurrentMessageId] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [openExpireDialog, setOpenExpireDialog] = useState(false);
	const [selectedMessageForExpire, setSelectedMessageForExpire] =
		useState(null);

	useEffect(() => {
		fetchMessages();
	}, []);

	const fetchMessages = async () => {
		try {
			const response = await fetch("https://nrgwappdata.com/messages");
			if (!response.ok) {
				throw new Error("Failed to fetch messages");
			}
			const data = await response.json();
			console.log(data); // Log the data to see what's being returned
			setMessages(data);
		} catch (error) {
			console.error("Error fetching messages:", error);
		}
	};

	const handleDialogOpen = (mess_id) => {
		setCurrentMessageId(mess_id);
		setOpenDialog(true);
	};
	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};
	const handleExpireIconClick = (mess_id) => {
		setSelectedMessageForExpire(mess_id);
		setOpenExpireDialog(true);
	};

	const deleteMessage = async () => {
		try {
			await fetch(`https://nrgwappdata.com/deleteMessage/${currentMessageId}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ mess_deleted: true }),
			});
			setOpenDialog(false);
			setOpenSnackbar(true);

			// Manually update the messages list to remove the deleted message
			setMessages((prevMessages) => {
				const updatedMessages = prevMessages.filter(
					(message) => message.mess_id !== currentMessageId
				);
				console.log(updatedMessages); // Check the updated state
				return updatedMessages;
			});
		} catch (error) {
			console.error("Error deleting message:", error);
		}
	};

	const markMessageAsExpired = async () => {
		try {
			await fetch(
				`https://nrgwappdata.com/markMessageAsExpired/${selectedMessageForExpire}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ mess_read: 1 }),
				}
			);
			setOpenExpireDialog(false); // Close the expire confirmation dialog
			setOpenSnackbar(true); // Show the success snackbar
			fetchMessages(); // Refresh the messages list
		} catch (error) {
			console.error("Error marking message as expired:", error);
			// Optionally, handle errors (e.g., showing an error snackbar)
		}
	};
	// Snackbar Alert Component
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});
	const convertTimestampToEST = (timestamp) => {
		const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
		const options = {
			timeZone: "America/New_York", // Eastern Standard Time (EST)
			weekday: "long", // Full day name (e.g., Monday)
			month: "long", // Full month name (e.g., February)
			day: "numeric",
			year: "numeric",
			hour12: true,
			hour: "numeric",
			minute: "numeric",
		};
		return date.toLocaleString("en-US", options);
	};
	const handleExpireMessage = async (mess_id) => {
		console.log("Marking message as expired:", mess_id);
		// You would typically make an API call here to update the message status
		// For demonstration, just log to the console
		// After updating, fetch the updated messages list
		fetchMessages();
	};
	const getAccordionHeaderColor = (urgency) => {
		switch (urgency) {
			case 0:
				return "#efeaea";
			case 1:
				return "#fcf067";
			case 2:
				return "#f99898";
			default:
				return "inherit";
		}
	};

	return (
		<div>
			{messages.length > 0 ? (
				messages.map((message) => (
					<Accordion
						key={message.mess_id}
						sx={{
							backgroundColor: getAccordionHeaderColor(message.urgency),
							marginBottom: "10px",
						}}
					>
						{/* <AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						sx={{
							justifyContent: "space-between",
							alignItems: "center",
							display: "flex",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
							<Typography>{message.mess_subject} - &nbsp; </Typography>
							<Typography
								style={{ fontSize: "10pt", position: "relative", top: "2px" }}
							>
								sent on: {convertTimestampToEST(message.mess_timestamp)}
							</Typography>
						</Box>
						<IconButton
							onClick={() => handleDialogOpen(message.mess_id)}
							aria-label="delete"
						>
							<DeleteIcon />
						</IconButton>
					</AccordionSummary> */}
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							sx={{
								justifyContent: "space-between",
								alignItems: "center",
								display: "flex",
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
								<Typography>{message.mess_subject} - &nbsp; </Typography>
								<Typography
									style={{ fontSize: "10pt", position: "relative", top: "2px" }}
								>
									sent on: {convertTimestampToEST(message.mess_timestamp)}
								</Typography>
							</Box>
							{/* <IconButton
							onClick={() => handleExpireIconClick(message.mess_id)}
							aria-label="expire"
							sx={{ marginRight: "8px" }}
						>
							<AccessTimeIcon />
						</IconButton> */}
							<IconButton
								onClick={() => handleDialogOpen(message.mess_id)}
								aria-label="delete"
							>
								<DeleteIcon />
							</IconButton>
						</AccordionSummary>

						<AccordionDetails sx={{ backgroundColor: "white" }}>
							<Typography
								dangerouslySetInnerHTML={{ __html: message.mess_body }}
							/>
						</AccordionDetails>
					</Accordion>
				))
			) : (
				<Typography sx={{ textAlign: "center", mt: 2 }}>
					<h3>Currently, no active messages can be found in the system.</h3>
				</Typography>
			)}
			<Dialog
				open={openExpireDialog}
				onClose={() => setOpenExpireDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Confirm Message Expiry"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to mark this message as expired?
						<br />
						<br />
						<strong>
							Doing so will remove it from all contractors views!
						</strong>{" "}
						<br />
						<br />
						This action can only be undone by a Super Admin.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenExpireDialog(false)}>Cancel</Button>
					<Button
						onClick={() => markMessageAsExpired(selectedMessageForExpire)}
						color="primary"
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			{/* Delete Confirmation Dialog */}
			<Dialog
				open={openDialog}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this message?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>Cancel</Button>
					<Button onClick={deleteMessage} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			{/* Success Snackbar */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity="success"
					sx={{ width: "100%" }}
				>
					Message successfully deleted.
				</Alert>
			</Snackbar>
		</div>
	);
};

export default MessageList;
