import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddUser.scss";
import {
	FormLabel,
	TextField,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Button,
	Grid,
} from "@mui/material";

const AddUser = () => {
	const now = Date.now();
	const [user, setUser] = useState({
		company: "",
		add_1: "",
		add_2: "",
		city: "",
		prov: "ON",
		postal: "",
		contractor_full_name: "",
		cell: "",
		email: "",
		alt_phone: "",
		active: "0",
		deleted: 0,
		access_level: "0",
		date_added: now,
		added_by: 1,
		password: "",
		confirm_password: "",
	});

	const [errors, setErrors] = useState({
		contractor_full_name: "",
		city: "",
		cell: "",
		email: "",
		password: "",
		confirm_password: "",
	});
	const navigate = useNavigate();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUser((prev) => ({ ...prev, [name]: value }));
		if (!!errors[name]) {
			setErrors({ ...errors, [name]: "" });
		}
	};

	const handleClick = async (e) => {
		e.preventDefault();
		try {
			await axios.post("https://nrgwappdata.com/users", user);
			navigate("/users");
		} catch (err) {
			console.log(err);
		}
	};
	const validateForm = () => {
		let tempErrors = {};
		let isValid = true;

		// Required fields validation
		if (!user.contractor_full_name) {
			isValid = false;
			tempErrors.contractor_full_name = "Full name is required";
		}
		if (!user.city) {
			isValid = false;
			tempErrors.city = "City is required";
		}
		if (!user.postal) {
			isValid = false;
			tempErrors.postal = "Postal code is required";
		}
		if (!user.cell) {
			isValid = false;
			tempErrors.cell = "Main contact number is required";
		}
		if (!user.email) {
			isValid = false;
			tempErrors.email = "Email address is required";
		} else if (!/\S+@\S+\.\S+/.test(user.email)) {
			isValid = false;
			tempErrors.email = "Email address is invalid";
		}
		if (!user.password) {
			isValid = false;
			tempErrors.password = "Password is required";
		}
		if (user.password !== user.confirm_password) {
			isValid = false;
			tempErrors.password = "Passwords do not match";
			tempErrors.confirm_password = "Passwords do not match";
		}

		setErrors(tempErrors);
		return isValid;
	};
	const handleCancel = () => {
		navigate(-1); // This will take the user back to the previous page
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			console.log("Form is valid");
			// Perform your submission logic here, e.g., making an API call
			navigate("/users");
		} else {
			console.log("Form is invalid");
			// Handle the invalid form case
		}
	};
	const handleBlur = (e) => {
		const { name, value } = e.target;

		// Validate email format for email field onBlur
		if (name === "email") {
			const emailRegex = /\S+@\S+\.\S+/;
			if (value && !emailRegex.test(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "Invalid email address format",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "", // Clear the error message if email format is valid
				}));
			}
		}
	};

	return (
		<div className="form_add_user" style={{ margin: "10px" }}>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							variant="outlined"
							placeholder="Company working for"
							onChange={handleChange}
							name="company"
							style={{ margin: "10px" }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							required
							variant="outlined"
							placeholder="Full Name"
							onChange={handleChange}
							name="contractor_full_name"
							style={{ margin: "10px" }}
							error={!!errors.contractor_full_name}
							helperText={errors.contractor_full_name}
						/>
					</Grid>

					<Grid item xs={12} md={12}>
						<TextField
							required
							value={user.add_1}
							variant="outlined"
							placeholder="Address"
							onChange={handleChange}
							onBlur={handleBlur}
							name="add_1"
							style={{ margin: "10px" }}
							error={!!errors.add_1}
							helperText={errors.add_1}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<TextField
							required
							value={user.add_2}
							variant="outlined"
							placeholder="Address line 2"
							onChange={handleChange}
							onBlur={handleBlur}
							name="add_2"
							style={{ margin: "10px" }}
							error={!!errors.add_2}
							helperText={errors.add_2}
						/>
					</Grid>

					{/* City, Prov, Postal with specified widths */}
					<Grid item xs={4} style={{ flexGrow: 1 }}>
						<TextField
							required
							variant="outlined"
							placeholder="City"
							onChange={handleChange}
							name="city"
							style={{ margin: "10px" }}
							error={!!errors.city}
							helperText={errors.city}
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth style={{ margin: "10px" }}>
							<Select
								labelId="prov-label"
								onChange={handleChange}
								name="prov"
								defaultValue="ON"
							>
								<MenuItem value="BC">British Columbia</MenuItem>
								<MenuItem value="MB">Manitoba</MenuItem>
								<MenuItem value="NB">New Brunswick</MenuItem>
								<MenuItem value="NL">Newfoundland and Labrador</MenuItem>
								<MenuItem value="NS">Nova Scotia</MenuItem>
								<MenuItem value="ON">Ontario</MenuItem>
								<MenuItem value="PE">Prince Edward Island</MenuItem>
								<MenuItem value="QC">Quebec</MenuItem>
								<MenuItem value="SK">Saskatchewan</MenuItem>
								<MenuItem value="NT">Northwest Territories</MenuItem>
								<MenuItem value="NU">Nunavut</MenuItem>
								<MenuItem value="YT">Yukon</MenuItem>
								<MenuItem value="BC">British Columbia</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							variant="outlined"
							placeholder="Postal/Zip"
							onChange={handleChange}
							name="postal"
							style={{ margin: "10px" }}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<TextField
							required
							variant="outlined"
							placeholder="Email Address"
							onChange={handleChange}
							name="email"
							style={{ margin: "10px" }}
							error={!!errors.email}
							helperText={errors.email}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							required
							variant="outlined"
							placeholder="Main Contact Number"
							onChange={handleChange}
							name="cell"
							style={{ margin: "10px" }}
							error={!!errors.cell}
							helperText={errors.cell}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							variant="outlined"
							placeholder="Alternate Contact Number"
							onChange={handleChange}
							name="alt_phone"
							style={{ margin: "10px" }}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth style={{ margin: "10px" }}>
							<Select
								labelId="isactive"
								onChange={handleChange}
								name="active"
								defaultValue="0"
							>
								<MenuItem value="1">User Is Active</MenuItem>
								<MenuItem value="0">User Is Inactive</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth style={{ margin: "10px" }}>
							<Select
								labelId="access_level"
								onChange={handleChange}
								name="access_level"
								defaultValue="0"
							>
								<MenuItem value="0">Contractor Not Yet Approved</MenuItem>
								<MenuItem value="1">Contractor Approved</MenuItem>
								<MenuItem value="2">NRGWise Employee</MenuItem>
								<MenuItem value="3">Super Admin</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							required
							type="password"
							variant="outlined"
							placeholder="Password"
							onChange={handleChange}
							name="password"
							style={{ margin: "10px" }}
							error={!!errors.password}
							helperText={errors.password}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							required
							type="password"
							variant="outlined"
							placeholder="Confirm Password"
							onChange={handleChange}
							name="confirm_password"
							style={{ margin: "10px" }}
							error={!!errors.confirm_password}
							helperText={errors.confirm_password}
						/>
					</Grid>
					{/* Save and Cancel Buttons */}
					<Grid item xs={6}>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleCancel}
							sx={{
								margin: "10px",
								color: "error.main", // Use the theme's error color for text
								borderColor: "error.main", // Use the theme's error color for the border
								"&:hover": {
									backgroundColor: "error.light", // A lighter red for the hover background
									color: "error.contrastText", // Ensures readable contrast on hover
								},
							}}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={6} style={{ textAlign: "right" }}>
						<Button
							variant="contained"
							onClick={handleSubmit}
							sx={{
								margin: "10px",
								backgroundColor: "#1976d2", // Your desired initial background color
								color: "#fff", // Text color
								"&:hover": {
									backgroundColor: "#1558a8", // Darker shade for hover effect
									color: "#ffffff", // You can adjust the text color on hover if needed
									// borderColor: "#123456", // Optional: if you want to change the border color on hover
								},
							}}
						>
							Save User Data
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export default AddUser;
