import React, { useState } from "react";
import ReactQuill from "react-quill";
import { modules, formats } from "./EditorToolbar"; // Ensure this path is correct
import "react-quill/dist/quill.snow.css";
import "../EditorStyles.css";
import EditorToolbar from "./EditorToolbar";

export const Editor = ({ value, onChange }) => {
	// const [value, setValue] = useState("");

	return (
		<div>
			<EditorToolbar />
			{/* <ReactQuill
				theme="snow"
				value={value} // Make sure this is the prop passed from the parent
				onChange={(content, delta, source, editor) =>
					setValue(editor.getHTML())
				} // Update to ensure the correct usage
				modules={modules}
				formats={formats}
			/> */}
			<ReactQuill
				theme="snow"
				value={value}
				onChange={onChange}
				modules={modules} // Apply custom toolbar configurations
				formats={formats}
			/>
		</div>
	);
};

export default Editor;
