import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import MessageForm from "./MessageForm"; // Update the import path as needed
import GroupingsManagement from "./GroupingsManagement";
import GroupsManagement from "./GroupsManage";
import MessageList from "./MessageList";
import "./EditorStyles.css";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default function Messaging() {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={value} onChange={handleChange} aria-label="messaging tabs">
					<Tab label="Compose" />
					<Tab label="Groups" />
					<Tab label="Grouping Assignment" />
					<Tab label="Sent Messages" />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<MessageForm />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<MessageList />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<GroupsManagement />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<GroupingsManagement />
			</TabPanel>
		</Box>
	);
}
