import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const ComOfficeSettings = () => {
	const [selectedType1, setSelectedType1] = useState("");
	const [newContractorType, setNewContractorType] = useState("");
	const [newPlannedUpgrade, setNewPlannedUpgrade] = useState("");
	const [contractorTypes, setContractorTypes] = useState([]);

	const [plannedUpgrades, setPlannedUpgrades] = useState([]);

	const [isContractorButtonDisabled, setContractorIsButtonDisabled] =
		useState(true);
	const [isPlannedButtonDisabled, setPlannedIsButtonDisabled] = useState(true);
	useEffect(() => {
		fetchContractorTypes();
		fetchPlannedUpgrades();
	}, []);

	const fetchContractorTypes = async () => {
		try {
			const response = await fetch("https://nrgwappdata.com/contractor-types");
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			console.log(data); // Log to see the actual structure
			if (Array.isArray(data)) {
				setContractorTypes(data);
			} else {
				console.error("Fetched data is not an array:", data);
				setContractorTypes([]); // Fallback to an empty array to avoid crashing
			}
		} catch (error) {
			console.error("Error fetching contractor types:", error);
			setContractorTypes([]); // Handle errors by setting to an empty array
		}
	};

	// Function to fetch planned upgrades
	const fetchPlannedUpgrades = async () => {
		try {
			const response = await fetch("https://nrgwappdata.com/planned-upgrades");
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			if (data.length > 0) {
				setPlannedUpgrades(data);
			} else {
				console.log("No planned upgrades found");
			}
		} catch (error) {
			console.error("Error fetching planned upgrades:", error);
		}
	};

	const handleTypeChange1 = (event) => {
		setSelectedType1(event.target.value);
	};

	const handleInputChangeContractor = (event) => {
		const newValue = event.target.value;
		setNewContractorType(newValue); // Correctly set the new contractor type
		setContractorIsButtonDisabled(newValue.length < 3);
	};

	const handleInputChangePlanned = (event) => {
		const newValue = event.target.value;
		setNewPlannedUpgrade(newValue); // Correctly set newPlannedUpgrade
		setPlannedIsButtonDisabled(newValue.length < 3); // Disable button based on newPlannedUpgrade's length
	};

	const handleNewContractorTypeChange = (event) => {
		setNewContractorType(event.target.value);
	};

	const handlePlannedUpgrade = () => {
		if (newPlannedUpgrade.trim() !== "") {
			// Assuming you want to add to the plannedUpgrades array, not somethingElse
			setPlannedUpgrades([...plannedUpgrades, newPlannedUpgrade.trim()]);
			setNewPlannedUpgrade(""); // Reset the newPlannedUpgrade input
		}
	};
	const handleAddContractorType = async () => {
		if (newContractorType.trim() !== "") {
			console.log("Sending new contractor type:", newContractorType);

			try {
				const response = await fetch(
					"https://nrgwappdata.com/update-contractor-types",
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ setting_value: newContractorType }),
					}
				);

				if (response.ok) {
					// Fetch the updated list of contractor types or update the state directly
					fetchContractorTypes();
					setNewContractorType(""); // Reset the input field
				} else {
					console.error("Failed to add new contractor type.");
				}
			} catch (error) {
				console.error("Error adding new contractor type:", error);
			}
		}
	};

	const handleAddPlannedUpgrade = async () => {
		if (newPlannedUpgrade.trim() !== "") {
			try {
				const response = await fetch(
					"https://nrgwappdata.com/update-planned-upgrades",
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ update_title: newPlannedUpgrade }),
					}
				);
				if (response.ok) {
					// Fetch the updated list of planned upgrades or update the state directly
					fetchPlannedUpgrades();
					setNewPlannedUpgrade(""); // Reset the input field
				} else {
					console.error("Failed to add new planned upgrade.");
				}
			} catch (error) {
				console.error("Error adding new planned upgrade:", error);
			}
		}
	};

	const handleDeleteContractorType = (index) => {
		const updatedContractorTypes = [...contractorTypes];
		updatedContractorTypes.splice(index, 1);
		setContractorTypes(updatedContractorTypes);
	};
	return (
		<div style={{ display: "flex", width: "95%" }}>
			<div style={{ flex: "1", marginRight: "10px" }}>
				<Card>
					<CardContent>
						<Typography variant="h5" component="h2">
							Contractor Types
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Existing Contractor Types
							<br />
							&nbsp;
						</Typography>
						<ul style={{ padding: 0 }}>
							{contractorTypes.map((type, index) => (
								<li
									key={index}
									style={{
										listStyle: "none",
										marginBottom: "8px",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center", // Center vertically
										backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
									}}
								>
									<div style={{ marginLeft: "10px" }} key={index}>
										{type.setting_value}
									</div>

									<div>
										<IconButton aria-label="edit">
											<EditIcon />
										</IconButton>
										<IconButton aria-label="delete">
											<DeleteIcon />
										</IconButton>
									</div>
								</li>
							))}
						</ul>
						<div style={{ marginBottom: "16px", marginTop: "15px" }}>
							<TextField
								label="Add New Contractor Type"
								value={newContractorType} // Use newContractorType here
								onChange={handleInputChangeContractor} // Correctly captures changes
								fullWidth
								margin="normal"
								variant="outlined"
							/>
						</div>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								variant="contained"
								color="primary"
								disabled={isContractorButtonDisabled}
								onClick={handleAddContractorType}
							>
								Add New Contractor Type
							</Button>
						</div>
					</CardContent>
				</Card>
			</div>
			<div style={{ flex: "1", marginLeft: "10px" }}>
				<Card>
					<CardContent>
						<Typography variant="h5" component="h2">
							Planned Upgrades
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Existing Planned Upgrades
							<br />
							&nbsp;
						</Typography>
						<ul style={{ padding: 0 }}>
							{plannedUpgrades.map(
								(
									upgrade,
									index // Corrected to use plannedUpgrades
								) => (
									<li
										key={index}
										style={{
											listStyle: "none",
											marginBottom: "8px",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center", // Center vertically
											backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
										}}
									>
										<div style={{ marginLeft: "10px" }}>
											{upgrade.update_title}
										</div>

										<div>
											<IconButton aria-label="edit">
												<EditIcon />
											</IconButton>
											<IconButton aria-label="delete">
												<DeleteIcon />
											</IconButton>
										</div>
									</li>
								)
							)}
						</ul>
						<div style={{ marginBottom: "16px", marginTop: "15px" }}>
							<TextField
								label="Add New Planned Upgrade"
								value={newPlannedUpgrade}
								onChange={handleInputChangePlanned}
								fullWidth
								margin="normal"
								variant="outlined"
								name="plannedUpgrade"
							/>
						</div>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								variant="contained"
								color="primary"
								disabled={isPlannedButtonDisabled}
								onClick={handleAddPlannedUpgrade}
							>
								Add New Planned Upgrade
							</Button>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default ComOfficeSettings;
