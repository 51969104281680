import React, { useState, useEffect } from "react";
import {
	Box,
	Select,
	MenuItem,
	Typography,
	Paper,
	Button,
} from "@mui/material";
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem"; // Adjust import based on your file structure
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GroupingsManagement = () => {
	const [selectedGroup, setSelectedGroup] = useState("");
	const [users, setUsers] = useState([]);
	const [groups, setGroups] = useState([]);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	useEffect(() => {
		fetchAllData();
	}, []);

	const fetchAllData = async () => {
		try {
			// Fetch groups
			const groupResponse = await fetch("https://nrgwappdata.com/appGroups");
			if (!groupResponse.ok) throw new Error("Failed to fetch groups");
			const groupData = await groupResponse.json();

			// Fetch users
			const userResponse = await fetch("https://nrgwappdata.com/appUsers");
			if (!userResponse.ok) throw new Error("Failed to fetch users");
			const userData = await userResponse.json();

			// Fetch group links
			const groupLinkResponse = await fetch(
				"https://nrgwappdata.com/groupLinks"
			);
			console.log(groupLinkResponse);
			if (!groupLinkResponse.ok) throw new Error("Failed to fetch group links");
			const groupLinkData = await groupLinkResponse.json();
			if (!Array.isArray(groupLinkData)) {
				throw new Error("Group links data is not an array");
			}

			// Process data to associate users with groups
			const processedUsers = userData.map((user) => ({
				...user,
				groupIds: groupLinkData
					.filter((link) => link.uid === user.uid)
					.map((link) => link.groupID),
			}));

			setGroups(groupData);
			setUsers(processedUsers);
		} catch (error) {
			console.error("Failed to fetch data", error);
			toast.error(error.message || "Failed to load groups and users.");
		}
	};
	const handleDragEnd = (event) => {
		const { active, over } = event;

		if (!over) {
			return;
		}

		const activeUserIndex = users.findIndex((user) => user.uid === active.id);
		const isCurrentlyGrouped =
			users[activeUserIndex].groupIds.includes(selectedGroup);
		let newUserList = [...users];

		if (isCurrentlyGrouped) {
			newUserList[activeUserIndex].groupIds = newUserList[
				activeUserIndex
			].groupIds.filter((id) => id !== selectedGroup);
		} else {
			newUserList[activeUserIndex].groupIds.push(selectedGroup);
		}

		setUsers(newUserList);
	};

	const handleSaveGroups = async () => {
		// Prepare data for saving
		const groupLinksToSave = users
			.filter((user) => user.groupIds.includes(selectedGroup))
			.map((user) => ({
				uid: user.uid,
				groupID: selectedGroup,
			}));

		try {
			const response = await fetch("https://nrgwappdata.com/updateGroupLinks", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(groupLinksToSave),
			});

			if (!response.ok) throw new Error("Network response was not ok");
			toast.success("Group links saved successfully");
			fetchAllData(); // Refresh all data to reflect the changes
		} catch (error) {
			console.error("Failed to save group links:", error);
			toast.error("Failed to save group links.");
		}
	};

	return (
		<Paper
			elevation={3}
			sx={{
				p: 2,
				margin: "auto",
				maxWidth: "95%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<ToastContainer />
			<Select
				value={selectedGroup}
				onChange={(e) => setSelectedGroup(e.target.value)}
				displayEmpty
				fullWidth
				variant="outlined"
				sx={{ mb: 2 }}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{groups.map((group) => (
					<MenuItem key={group.groupID} value={group.groupID}>
						{group.group_name}
					</MenuItem>
				))}
			</Select>

			{selectedGroup && (
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<Box sx={{ display: "flex", gap: 2 }}>
						<SortableList
							title="Available Users"
							users={users.filter(
								(user) => !user.groupIds.includes(selectedGroup)
							)}
						/>
						<SortableList
							title="Grouped Users"
							users={users.filter((user) =>
								user.groupIds.includes(selectedGroup)
							)}
						/>
					</Box>
					<Button variant="contained" sx={{ mt: 2 }} onClick={handleSaveGroups}>
						Save Groups
					</Button>
				</DndContext>
			)}
		</Paper>
	);
};

// Helper component for rendering lists
const SortableList = ({ title, users }) => (
	<Box sx={{ flex: 1 }}>
		<Typography variant="h6">{title}</Typography>
		<SortableContext
			items={users.map((user) => user.uid)}
			strategy={verticalListSortingStrategy}
		>
			{users.map((user) => (
				<SortableItem
					key={user.uid}
					id={user.uid}
					name={user.contractor_full_name}
				/>
			))}
		</SortableContext>
	</Box>
);

export default GroupingsManagement;
