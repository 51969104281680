import React from "react";
import ReactDOM from "react-dom/client";
import "./App.scss";
import App from "./App";
import { LicenseInfo } from "@mui/x-license-pro";
import { AuthProvider } from "./context/AuthProvider";

LicenseInfo.setLicenseKey(
	"29a9ead40418bcc3d3e55f6e0d08aed2Tz03NTY0NCxFPTE3Mjc1NDkyMzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("./sw.js")
		.then((registration) => {
			console.log("Service Worker registered with scope:", registration.scope);
		})
		.catch((error) => {
			console.error("Service Worker registration failed:", error);
		});
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AuthProvider>
			<App />
		</AuthProvider>
	</React.StrictMode>
);
