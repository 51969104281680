import React, { useState, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import MyNavbar from "../../pages/dashboard/MyNavbar";
import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";
import "./MyMessaging.scss"; // Ensure this CSS file exists and is correctly linked
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const MyMessaging = () => {
	const [messages, setMessages] = useState([]);
	// const { currentUser } = useContext(AuthContext); // Uncomment if using AuthContext for current user info
	const currentUserId = 2; // Example currentUserId, replace with actual data as needed

	useEffect(() => {
		fetchMessages();
	}, []);

	const fetchMessages = async () => {
		try {
			const response = await axios.get("https://nrgwappdata.com/messages");
			setMessages(response.data);
		} catch (error) {
			console.error("Failed to fetch messages:", error);
		}
	};

	const formatDate = (epochTimestamp) => {
		const date = new Date(epochTimestamp * 1000);
		return date.toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		});
	};

	const createMarkup = (htmlContent) => {
		return { __html: DOMPurify.sanitize(htmlContent) };
	};
	const getUrgencyClass = (urgency) => {
		switch (urgency) {
			case 2:
				return "urgent";
			case 1:
				return "important";
			default:
				return "normal";
		}
	};

	return (
		<>
			<div className="myMessagesContainer">
				<div className="myMessagesHeader">
					<img
						src={CompanyLogo}
						alt="Company Logo"
						className="logoImageMyMessages"
					/>
				</div>
				<MyNavbar />
				<div className="headerMessageCopy">
					<span className="messageStatusText">My Message Center</span>
				</div>
				<div className="myMessagesLegend">
					<div className="legendItem">
						<span className="dot normal"></span>
						<span>Normal</span>
					</div>

					<div className="legendItem">
						<span className="dot important"></span>
						<span>Important</span>
					</div>
					<div className="legendItem">
						<span className="dot urgent"></span>
						<span>Urgent</span>
					</div>
				</div>
				{messages.map((message) => (
					<Accordion key={message.mess_id} className="messageAccordion">
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							className={`urgencyIndicatorRow ${getUrgencyClass(
								message.urgency
							)}`}
							style={{ minHeight: "80px" }}
						>
							<div style={{ width: "100%" }}>
								<h3>{message.mess_subject}</h3>
								<span>Sent: {formatDate(message.mess_timestamp)}</span>
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<div
								className="messageContent"
								dangerouslySetInnerHTML={createMarkup(message.mess_body)}
							/>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		</>
	);
};
