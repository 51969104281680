import React, { useEffect, useState } from "react";

import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/NavBar";
import Table from "../../components/table/Table";

import "./UsersStyles.scss";

const Users = () => {
	return (
		<div className="allusers">
			<Sidebar />
			<div className="allusersContainer">
				{/* <Navbar /> */}
				<h3>All Users</h3>
				<div className="systemUsers">
					<Table />
				</div>
			</div>
		</div>
	);
};
export default Users;
