import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Navigate, Link, useLocation } from "react-router-dom";

const NotFound = () => {
	const { authState } = useContext(AuthContext);
	const location = useLocation();
	const token = authState.token;

	// Check if the user is authenticated and the token is not null
	const isAuthenticatedWithToken = authState.isAuthenticated && token !== null;

	if (!isAuthenticatedWithToken) {
		return <Navigate to="/" />;
	}
	return <div>NotFound</div>;
};

export default NotFound;
