import { createContext, useState, useEffect, useReducer } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState({
		isAuthenticated: false,
		user: null,
		token: null,
		additionalUserInfo: null,
	});

	useEffect(() => {
		const token = localStorage.getItem("token");
		//console.log("Token:", token);

		if (token) {
			try {
				const decodedToken = jwtDecode(token); // Decode the token
				//console.log("Decoded Token:", decodedToken);
				const user = {
					id: decodedToken.id,
					email: decodedToken.email,
					urole: decodedToken.urole,
				};
				// Assuming additionalUserInfo is part of the decoded token
				const additionalUserInfo = decodedToken.additionalUserInfo;
				//console.log("Additional User Info: ", additionalUserInfo);
				setAuthState({
					isAuthenticated: true,
					user,
					token,
					additionalUserInfo,
				});
			} catch (error) {
				console.error("Error decoding token:", error);
				// Consider logging out or clearing the token if it's invalid
			}
		}
	}, []);
	//	console.log("Auth State:", authState);
	const login = async (email, password) => {
		try {
			const response = await axios.post("https://nrgwappdata.com/login", {
				email,
				password,
			});
			const { token, user, additionalUserInfo } = response.data;
			//console.log("Here is my user data : ", user);
			localStorage.setItem("token", token);
			//localStorage.setItem("authUser", JSON.stringify(response));
			setAuthState({
				isAuthenticated: true,
				user: {
					id: user.id,
					email: user.email,
					urole: user.urole,
				},
				token,
				additionalUserInfo, // Set additionalUserInfo from response
			});
		} catch (error) {
			console.error("Login failed:", error);
			throw new Error(error.response?.data?.message || "Login failed");
		}
	};

	const logout = () => {
		localStorage.removeItem("token");
		setAuthState({
			isAuthenticated: false,
			user: null,
			token: null,
			additionalUserInfo: null,
		});
	};
	const [user, setUser] = useState(null);
	const [additionalInfo, setAdditionalInfo] = useState({});

	return (
		<AuthContext.Provider value={{ authState, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};
