import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import ModeIcon from "@mui/icons-material/Mode";
import { useEffect, useState } from "react"; // Import useEffect and useState
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

const DataTable = () => {
	const [users, setUsers] = useState([]); // Initialize users state
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get("https://nrgwappdata.com/users");
				setUsers(response.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, []);

	const columns = [
		{
			field: "contractor_full_name",
			headerName: "Full Name",
			flex: 1,
			minWidth: 200,
			sortable: true,
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},

		{
			field: "add_1",
			headerName: "Address",
			flex: 1,
			minWidth: 230,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "city",
			headerName: "City",
			flex: 1,
			minWidth: 120,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "prov",
			headerName: "Prov.",
			flex: 1,
			minWidth: 80,
			maxWidth: 80,
			sortable: false,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "cell",
			headerName: "Main Phone",
			flex: 1,
			sortable: true,
			minWidth: 150,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email Address",
			flex: 1,
			minWidth: 330,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "edit",
			headerName: "Edit",
			flex: 1,
			sortable: false,
			minWidth: 80,
			maxWidth: 80,
			type: "text",
			headerAlign: "right",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "right",
							fontSize: "12px",
							paddingRight: "10px",
						}}
					>
						<NavLink to={`${cellValues.id}`}>edit</NavLink>
					</div>
				);
			},
		},
	];

	return (
		<div className="table">
			<div className="usersContainer">
				<DataGrid
					rows={users} // Use the fetched users data here
					columns={columns}
					getRowId={(row) => row.uid}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 15 },
						},
					}}
					pageSizeOptions={[5, 10, 15, 25]}
				/>
			</div>
		</div>
	);
};

export default DataTable;
