import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	Snackbar,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "./EditUser.scss";

const EditUser = () => {
	const [user, setUser] = useState({
		company: "",
		add_1: "",
		add_2: "",
		city: "",
		prov: "",
		postal: "",
		contractor_full_name: "",
		cell: "",
		email: "",
		alt_phone: "",
		active: 0,
		deleted: 0,
		access_level: 2048,
	});

	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const navigate = useNavigate();
	const { editID } = useParams();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [errors, setErrors] = useState({
		contractor_full_name: "",
		city: "",
		cell: "",
		email: "",
	});

	const [formChanged, setFormChanged] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://nrgwappdata.com/userspecific/${editID}`
				);
				console.log("Fetched Data:", response.data); // Verify the structure
				// Assuming the structure is directly the user object
				setUser(response.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [editID]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		// Set formChanged to true when any field is changed
		setFormChanged(true);

		// Validate email format for email field
		if (name === "email") {
			const emailRegex = /\S+@\S+\.\S+/;
			if (!emailRegex.test(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "Invalid email address format",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "", // Clear the error message if email format is valid
				}));
			}
		}

		// Validate phone number format for cell and alt_phone fields
		if (name === "cell" || name === "alt_phone") {
			if (value.trim() !== "") {
				if (isValidPhoneNumber(value)) {
					// Clear error message if format is valid
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: "",
					}));
				} else {
					// Show error message for invalid format
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]:
							"Invalid phone number format. Expected format #-###-###-#### (i.e. 1-519-235-6541)",
					}));
				}
			} else {
				// Clear error message if value is empty
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "",
				}));
			}
		}

		// Clear error message for required fields if they pass validation
		if (
			value.trim() !== "" &&
			(name === "contractor_full_name" ||
				name === "city" ||
				name === "cell" ||
				name === "email")
		) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: "",
			}));
		}

		// Update state with new value
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleBlur = (e) => {
		const { name, value } = e.target;

		// Validate email format for email field onBlur
		if (name === "email") {
			const emailRegex = /\S+@\S+\.\S+/;
			if (value && !emailRegex.test(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "Invalid email address format",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "", // Clear the error message if email format is valid
				}));
			}
		}
	};
	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		// Validation
		let formIsValid = true;
		const newErrors = {
			contractor_full_name: "",
			city: "",
			cell: "",
			email: "",
			password: "",
			confirm_password: "",
		};

		if (!user.contractor_full_name.trim()) {
			newErrors.contractor_full_name = "Full Name is required";
			formIsValid = false;
		}

		if (!user.city.trim()) {
			newErrors.city = "City is required";
			formIsValid = false;
		}

		if (!user.cell.trim()) {
			newErrors.cell = "Main Contact Number is required";
			formIsValid = false;
		}

		if (!user.email.trim()) {
			newErrors.email = "Email Address is required";
			formIsValid = false;
		} else if (!/\S+@\S+\.\S+/.test(user.email)) {
			newErrors.email = "Email Address is invalid";
			formIsValid = false;
		}

		if (user.password !== user.confirm_password) {
			newErrors.confirm_password = "Passwords do not match";
			formIsValid = false;
		}

		setErrors(newErrors);

		if (formIsValid) {
			try {
				// Assuming your API expects the user ID as part of the body
				// If it's expected in the URL, adjust the axios URL accordingly
				const response = await axios.post(
					`https://nrgwappdata.com/updateuser`,
					{
						...user,
						uid: editID,
					}
				);

				console.log(response.data); // For debugging

				// Show success snackbar
				setSnackbarMessage("User successfully updated.");
				setOpenSnackbar(true);
				// Optionally, navigate the user away or refresh the user data
				// navigate("/users");
			} catch (error) {
				console.error("Error updating user:", error);
				// Optionally, show an error message in the UI
			}
		}
	};

	const handleDelete = async () => {
		try {
			await axios.delete(`https://nrgwappdata.com/users/${editID}`);
			setSnackbarMessage("User successfully deleted.");
			setOpenSnackbar(true);
			navigate("/users"); // Adjust this route as necessary
		} catch (error) {
			console.error("Error deleting user:", error);
		}
	};
	const handleDeleteClick = () => {
		setOpenDeleteDialog(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await axios.post(`https://nrgwappdata.com/deleteUser/${editID}`);
			setSnackbarMessage("User successfully deleted.");
			setOpenSnackbar(true);
			navigate("/users"); // Adjust this route as necessary
		} catch (error) {
			console.error("Error deleting user:", error);
		} finally {
			setOpenDeleteDialog(false); // Close the dialog regardless of the outcome
		}
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	// Snackbar Alert Component
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});
	const now = Date.now();
	const handleCancel = () => {
		navigate(-1); // This will take the user back to the previous page
	};
	const isValidPhoneNumber = (phoneNumber) => {
		// Regular expression to match phone number format "1-###-###-####"
		const phoneRegex = /^1-\d{3}-\d{3}-\d{4}$/;

		return phoneRegex.test(phoneNumber);
	};

	return (
		<div className="form_add_user" style={{ margin: "10px" }}>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Company"
							name="company"
							value={user.company}
							onChange={handleChange}
							required
						/>
					</Grid>
					{/* More form fields go here */}
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Contractor Full Name"
							name="contractor_full_name"
							value={user.contractor_full_name}
							onChange={handleChange}
							onBlur={handleBlur}
							required
							error={Boolean(errors.contractor_full_name)}
							helperText={errors.contractor_full_name}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Main Contact Number"
							name="cell"
							value={user.cell}
							onChange={handleChange}
							onBlur={handleBlur}
							required
							error={Boolean(errors.cell)}
							helperText={errors.cell}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Email Address"
							name="email"
							value={user.email}
							onChange={handleChange}
							onBlur={handleBlur}
							required
							error={Boolean(errors.email)}
							helperText={errors.email}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<Select
								name="access_level"
								value={user.access_level}
								onChange={handleChange}
								required
							>
								<MenuItem value={1}>User</MenuItem>
								<MenuItem value={2048}>Admin</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} style={{ textAlign: "center" }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={!formChanged} // Disable button when no changes made
						>
							Update User
						</Button>
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleCancel}
							style={{ marginLeft: "10px" }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="error"
							onClick={handleDeleteClick}
							style={{ marginLeft: "10px" }}
						>
							Delete User
						</Button>
					</Grid>
				</Grid>
			</form>

			<Dialog
				open={openDeleteDialog}
				onClose={handleCloseDeleteDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this user?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDeleteDialog} color="primary">
						Cancel
					</Button>
					<Button onClick={handleConfirmDelete} color="error" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<Alert onClose={handleSnackbarClose} severity="success">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default EditUser;
