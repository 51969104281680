import React, { useState, useEffect } from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";

import MuiAlert from "@mui/material/Alert";

const DeletedMessages = () => {
	const [messages, setMessages] = useState([]);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
	const [selectedMessageForRestore, setSelectedMessageForRestore] =
		useState(null);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [selectedMessageForDelete, setSelectedMessageForDelete] =
		useState(null);

	useEffect(() => {
		fetchDeletedMessages();
	}, []);

	const fetchDeletedMessages = async () => {
		try {
			const response = await fetch(
				"https://nrgwappdata.com/deletedMessages?mess_read=1"
			); // Filter messages where mess_read is 1
			if (!response.ok) {
				throw new Error("Failed to fetch deleted messages");
			}
			const data = await response.json();
			console.log(data); // Log the data to see what's being returned
			setMessages(data);
		} catch (error) {
			console.error("Error fetching deleted messages:", error);
		}
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	const handleRestoreIconClick = (mess_id) => {
		setSelectedMessageForRestore(mess_id);
		setOpenRestoreDialog(true);
	};
	const convertTimestampToEST = (timestamp) => {
		const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
		const options = {
			timeZone: "America/New_York", // Eastern Standard Time (EST)
			weekday: "long", // Full day name (e.g., Monday)
			month: "long", // Full month name (e.g., February)
			day: "numeric",
			year: "numeric",
			hour12: true,
			hour: "numeric",
			minute: "numeric",
		};
		return date.toLocaleString("en-US", options);
	};
	const restoreMessage = async () => {
		try {
			await fetch(
				`https://nrgwappdata.com/restoreMessage/${selectedMessageForRestore}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ mess_read: 0 }),
				}
			);
			setOpenRestoreDialog(false);
			setOpenSnackbar(true);
			fetchDeletedMessages();
		} catch (error) {
			console.error("Error restoring message:", error);
		}
	};

	const handleDeleteIconClick = (mess_id) => {
		setSelectedMessageForDelete(mess_id);
		setOpenDeleteDialog(true);
	};
	const deleteMessage = async () => {
		try {
			await fetch(
				`https://nrgwappdata.com/permDeleteMessage/${selectedMessageForDelete}`,
				{
					method: "POST",
				}
			);
			setOpenDeleteDialog(false);
			fetchDeletedMessages();
		} catch (error) {
			console.error("Error deleting message:", error);
		}
	};

	const getUrgencyStyle = (urgency) => {
		switch (urgency) {
			case 0:
				return { backgroundColor: "#efeaea" }; // Light gray for normal
			case 1:
				return { backgroundColor: "#fcf067" }; // Yellow for important
			case 2:
				return { backgroundColor: "#f99898" }; // Red for urgent
			default:
				return {}; // Default style if needed
		}
	};

	return (
		<div class="messagesContainer" style={{ marginTop: "20px" }}>
			{messages.length > 0 ? (
				messages.map((message) => (
					<Accordion
						key={message.mess_id}
						sx={{
							width: "95%",
							margin: "0 auto",
							marginBottom: "10px",
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel1a-content-${message.mess_id}`}
							id={`panel1a-header-${message.mess_id}`}
							sx={{
								...getUrgencyStyle(message.urgency),
								justifyContent: "space-between",
								alignItems: "center",
								display: "flex",
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
								<Typography>{message.mess_subject} - &nbsp;</Typography>
								<Typography
									sx={{ fontSize: "10pt", position: "relative", top: "2px" }}
								>
									sent on: {convertTimestampToEST(message.mess_timestamp)}
								</Typography>
							</Box>
							<IconButton
								onClick={() => handleRestoreIconClick(message.mess_id)}
								aria-label="restore"
								sx={{ marginRight: "8px" }}
							>
								<RestoreIcon />
							</IconButton>
							<IconButton
								onClick={() => handleDeleteIconClick(message.mess_id)}
								aria-label="delete"
							>
								<DeleteIcon />
							</IconButton>
						</AccordionSummary>
						<AccordionDetails sx={{ backgroundColor: "white" }}>
							<Typography
								dangerouslySetInnerHTML={{ __html: message.mess_body }}
							/>
						</AccordionDetails>
					</Accordion>
				))
			) : (
				<Typography sx={{ textAlign: "center", mt: 2 }}>
					<h3>Currently, no deleted messages can be found in the system.</h3>
				</Typography>
			)}

			{/* Restore Confirmation Dialog */}
			<Dialog
				open={openRestoreDialog}
				onClose={() => setOpenRestoreDialog(false)}
				aria-labelledby="restore-dialog-title"
				aria-describedby="restore-dialog-description"
			>
				<DialogTitle id="restore-dialog-title">
					{"Confirm Message Restoration"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="restore-dialog-description">
						Are you sure you want to restore this message?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenRestoreDialog(false)}>Cancel</Button>
					<Button onClick={restoreMessage} color="primary" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			{/* Delete Confirmation Dialog */}
			<Dialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				aria-labelledby="delete-dialog-title"
				aria-describedby="delete-dialog-description"
			>
				<DialogTitle id="delete-dialog-title">
					{"Confirm Message Deletion"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="delete-dialog-description">
						Are you sure you want to permanently delete this message?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
					<Button onClick={deleteMessage} color="primary" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			{/* Success Snackbar */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<MuiAlert
					onClose={handleSnackbarClose}
					severity="success"
					sx={{ width: "100%" }}
				>
					Message successfully restored.
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default DeletedMessages;
