import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/NavBar";
import { useNavigate } from "react-router-dom";
import EditMyUser from "../../components/edituser/EditUser";

import "./UsersStyles.scss";

const EditUser = () => {
	return (
		<div className="addusers">
			<Sidebar />
			<div className="addusersContainer">
				{/* <Navbar /> */}
				<h3>Edit User</h3>
				<div className="users">
					<div className="usersContainer">
						<EditMyUser />
					</div>
				</div>
			</div>
		</div>
	);
};
export default EditUser;
