import React, { useReg, useState, useEffect } from "react";

import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Form, NavLink } from "react-router-dom";

import MaterialFormComponent from "../../components/register/Register.jsx";
import "./Register.scss";

const Register = () => {
	return (
		<div className="registerContainer">
			<div className="register">
				<div className="leftSide">
					<div className="logo">
						<img src={CompanyLogo} alt="NRGWise" className="logo" />
					</div>
				</div>
				<div className="rightSide">
					<div className="registerText">
						<h2 className="registerHeader">
							Set your business for success – training, tools and coaching
						</h2>
						<div className="italics">
							Energy Advisors and Trainees: Choose Smart NRGwise now makes it
							easier to find the tools and training you want. With a new online
							outlet and a new location, the company provides quick access to
							equipment and courses to grow and support your business.
						</div>
					</div>
				</div>
			</div>
			<div className="registerArea">
				<hr className="horizLine" />
				<div className="registerSection">
					<MaterialFormComponent />
					<div className="loginNow">
						Have an account already?<br></br>
						<NavLink to="/">Click here to login now</NavLink>
					</div>
				</div>
			</div>

			<div className="footer">
				<p>&copy;2023 NRG Wise. All rights reserved.</p>
				<p>&nbsp; </p>
			</div>
		</div>
	);
};
export default Register;
