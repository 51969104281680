import React from "react";
import Sidebar from "../../components/sidebar/SideBar";

import Messages from "../../components/messaging/Messaging";

import "./Messaging.scss";

const Messaging = () => {
	return (
		<div className="messaging">
			<Sidebar />

			<div className="messagingContainer">
				{/* <Navbar /> */}
				<h3 className="title">Messaging System</h3>
				<Messages />
			</div>
		</div>
	);
};
export default Messaging;
