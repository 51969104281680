import React, { useContext, useState, useEffect } from "react";
import MyNavbar from "../../pages/dashboard/MyNavbar";
import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";
import { AuthContext } from "../../context/AuthProvider";

import "./MySettings.scss";
import {
	Grid,
	TextField,
	Button,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormHelperText,
} from "@mui/material";
import axios from "axios";

export const MySettings = () => {
	const { authState } = useContext(AuthContext); // Access authState from AuthContext

	const { user, additionalInfo } = useContext(AuthContext);
	const initialValues = {
		company: "",
		contractor_full_name: "",
		cell: "",
		alt_phone: "",
		email: "",
		city: "",
		prov: "ON",
		postal: "",
	};

	const [formValues, setFormValues] = useState([]);
	const [errors, setErrors] = useState({});
	const [successMessage, setSuccessMessage] = useState("");

	useEffect(() => {
		console.log("Checking user info:", user);
		if (authState.isAuthenticated && user && user.additionalUserInfo) {
			const newFormValues = {
				company: user.additionalUserInfo.companyName || initialValues.company,
				contractor_full_name:
					user.additionalUserInfo.fullName ||
					initialValues.contractor_full_name,
				cell: user.additionalUserInfo.cellNumber || initialValues.cell,
				alt_phone: user.additionalUserInfo.altNumber || initialValues.alt_phone,
				email: user.email || initialValues.email,
				city: user.additionalUserInfo.city || initialValues.city,
				prov: user.additionalUserInfo.prov || initialValues.prov,
				postal: user.additionalUserInfo.postal || initialValues.postal,
			};
			setFormValues(newFormValues);
		}
	}, [authState.isAuthenticated, user]); // Dependency array ensures this runs when authState.isAuthenticated or user changes.

	const validate = () => {
		let tempErrors = {};
		// Add validation logic for other fields
		tempErrors.contractor_full_name = formValues.contractor_full_name
			? ""
			: "This field is required.";
		// Validate other fields similarly
		setErrors(tempErrors);
		return Object.values(tempErrors).every((x) => x === "");
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validate()) return;

		try {
			const response = await axios.post(
				"https://nrgwappdata.com/update-settings",
				formValues
			);

			setSuccessMessage("Your settings have been updated successfully.");
		} catch (error) {
			//	console.error("Update settings error:", error);
		}
	};
	console.log("AuthState:", authState);
	console.log("User:", user);

	console.log("CURENTLY MY FORMS VALUES ARE SET TO: ", formValues);
	return (
		<>
			<div className="mySettingsContainer">
				<div className="mySettingsHeader">
					<img
						src={CompanyLogo}
						alt="NRGWise Logo"
						className="logoImageMySettings"
					/>
				</div>
				<MyNavbar />
				<div className="headerCopy">
					<span className="bookingStatusText">My&nbsp;Settings</span>
				</div>
				<div>
					<form onSubmit={handleSubmit}>
						<Grid container alignItems="center" spacing={2}>
							<Grid item xs={12}>
								<TextField
									id="company"
									name="company"
									label="Company Name"
									type="text"
									value={formValues.company}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<Grid item xs={12} md={6}>
									<TextField
										id="contractor_full_name"
										name="contractor_full_name"
										label="Your Full Name *"
										type="text"
										value={formValues.contractor_full_name}
										onChange={handleInputChange}
										fullWidth
										sx={{ padding: "10px", width: "94%" }}
										error={!!errors.contractor_full_name}
										helperText={errors.contractor_full_name}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="email"
									name="email"
									label="Email Address *"
									type="text"
									value={formValues.email}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
									error={!!errors.email}
									helperText={errors.email}
								/>
							</Grid>

							<Grid item xs={12} md={4}>
								<TextField
									id="city"
									name="city"
									label="City *"
									type="text"
									value={formValues.city}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
									error={!!errors.city}
									helperText={errors.city}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl
									fullWidth
									sx={{ padding: "10px" }}
									error={!!errors.prov}
								>
									<InputLabel id="prov_label">Province/Territory *</InputLabel>
									<Select
										labelId="prov_label"
										id="prov"
										name="prov"
										value={formValues.prov}
										label="Province/Territory *"
										onChange={handleInputChange}
										sx={{ height: "45px", padding: "10px", width: "94%" }}
									>
										<MenuItem key="ab" value="AB">
											Alberta
										</MenuItem>
										<MenuItem key="bc" value="BC">
											British Columbia
										</MenuItem>
										<MenuItem key="mb" value="MB">
											Manitoba
										</MenuItem>
										<MenuItem key="nb" value="NB">
											New Brunswick
										</MenuItem>
										<MenuItem key="nl" value="NL">
											Newfoundland & Labrador
										</MenuItem>
										<MenuItem key="nt" value="NT">
											Northwest Territories
										</MenuItem>
										<MenuItem key="ns" value="NS">
											Nova Scotia
										</MenuItem>
										<MenuItem key="nu" value="NU">
											Nunavut
										</MenuItem>
										<MenuItem key="on" value="ON">
											Ontario
										</MenuItem>
										<MenuItem key="pe" value="PE">
											Prince Edward island
										</MenuItem>
										<MenuItem key="qc" value="QC">
											Quebec
										</MenuItem>
										<MenuItem key="sk" value="SK">
											Saskatchewan
										</MenuItem>
										<MenuItem key="yt" value="YT">
											Yukon
										</MenuItem>
									</Select>
									{errors.prov && (
										<FormHelperText>{errors.prov}</FormHelperText>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={12} md={2}>
								<TextField
									id="postal"
									name="postal"
									label="Postal *"
									type="text"
									value={formValues.postal}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
									error={!!errors.postal}
									helperText={errors.postal}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									id="cell"
									name="cell"
									label="Contact Number *"
									type="text"
									value={formValues.cell}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
									error={!!errors.cell}
									helperText={errors.cell}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="alt_phone"
									name="alt_phone"
									label="Alternate Contact Number"
									type="text"
									value={formValues.alt_phone}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="u_pass"
									name="u_pass"
									placeholder="Password *"
									type="password"
									value={formValues.u_pass}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
									error={!!errors.u_pass}
									helperText={errors.u_pass}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="confirm_pass"
									name="confirm_pass"
									placeholder="Confirm Password *"
									type="password"
									value={formValues.confirm_pass}
									onChange={handleInputChange}
									fullWidth
									sx={{ padding: "10px", width: "94%" }}
									error={!!errors.confirm_pass}
									helperText={errors.confirm_pass}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							style={{ textAlign: "center", marginTop: "20px" }}
						>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								fullWidth
								style={{
									backgroundColor: "green",
									marginTop: "25px",
									marginBottom: "25px",
									width: "55%",
									margin: "0 auto", // Center horizontally
								}}
							>
								Save My Settings
							</Button>
						</Grid>
					</form>
				</div>
			</div>

			<div className="footer">
				<p>&copy;2023 NRG Wise. All rights reserved.</p>
				<p>&nbsp; </p>
			</div>
		</>
	);
};
