import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./context/AuthProvider"; // Import your AuthContext

const ProtectedRoute = ({ children, allowedRoles }) => {
	const { authState } = useContext(AuthContext); // Use useContext to access authState
	const token = authState.token;
	const userRole = authState.additionalUserInfo?.accessLevel;
	const userHasRequiredRole =
		userRole !== undefined && allowedRoles.includes(userRole);

	const isAuthenticatedWithToken = authState.isAuthenticated && token !== null;

	if (!authState.isAuthenticated) {
		return <Navigate to="/" />;
	} else if (!userHasRequiredRole) {
		// You can return the Access Denied page here if needed
		return <Navigate to="/not-authorized" />;
	} else {
		return children || <Outlet />;
	}
};

export default ProtectedRoute;
