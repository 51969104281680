import React from "react";

import NewBooking from "../../components/addbooking/NewBooking";

function AddBooking() {
	return (
		<>
			<NewBooking />
		</>
	);
}

export default AddBooking;
