import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "./Messaging.scss";

import Editor from "./js/Editor";

const MessageForm = () => {
	const [sendToGroup, setSendToGroup] = useState([]);
	const [sendToUser, setSendToUser] = useState([]);
	const [sendViaEmail, setSendViaEmail] = useState(false);
	const [subject, setSubject] = useState("");
	const [urgency, setUrgency] = useState(0);
	const [editorContent, setEditorContent] = useState(""); // State for editor content
	const [groups, setGroups] = useState([]); // State for storing groups data
	const [users, setUsers] = useState([]); // State for storing users data
	const [validationMessage, setValidationMessage] = useState("");

	// New state for error tracking
	const [errors, setErrors] = useState({
		subject: false,
		recipient: false, // Combined error state for group/user recipient selection
	});

	const validateForm = () => {
		const newErrors = { subject: false, recipient: false };
		let isValid = true;

		// Subject must not be empty
		if (!subject.trim()) {
			newErrors.subject = true;
			isValid = false;
		}

		// Either sendToGroup or sendToUser must have a selection
		if (sendToGroup.length === 0 && sendToUser.length === 0) {
			newErrors.recipient = true;
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};
	useEffect(() => {
		// Fetch groups
		fetch("https://nrgwappdata.com/appGroups")
			.then((response) => response.json())
			.then((data) => setGroups(data))
			.catch((error) => console.error("Error fetching groups:", error));

		// Fetch users with access level 3025
		fetch("https://nrgwappdata.com/appUsers")
			.then((response) => response.json())
			.then((data) => setUsers(data))
			.catch((error) => console.error("Error fetching users:", error));
	}, []); // The empty array ensures this effect runs only once after the initial render

	const [freshFormData, setFreshFormData] = useState({
		send_to_users: 0,
		group_send: 0,
		mess_type: 0,
		mess_subject: "",
		mess_timestamp: Math.floor(Date.now() / 1000), // Current epoch time
		mess_body: "",
		parent_id: 0,
		urgency: 0,
		mess_read: 0,
		mess_sent_by: 0,
		mess_booking: 0,
		mess_deleted: 0,
	});

	const [submitStatus, setSubmitStatus] = useState({
		message: "",
		type: "", // 'success' or 'error'
	});
	// Handler for form field changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFreshFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));

		// More code will go here
	};
	const resetForm = () => {
		setSendToGroup([]);
		setSendToUser([]);
		setSendViaEmail(false);
		setSubject("");
		setUrgency("0");
		setEditorContent(""); // Assuming you have a state for editor content
	};
	function generateUniqueId() {
		const epochTime = Date.now(); // Current time in milliseconds
		const randomTwoDigit = Math.floor(Math.random() * 90 + 10); // Generates a number between 10 and 99
		return `${epochTime}-${randomTwoDigit}`;
	}
	const handleSubmit = async (event) => {
		event.preventDefault();
		const isFormValid = validateForm();
		const mess_grouping = generateUniqueId();

		if (!isFormValid) {
			return; // Stop form submission if validation fails
		}

		if (!editorContent.trim() || editorContent.trim() === "<p><br></p>") {
			// Adjust the HTML check as needed for your specific editor's default "empty" state
			toast.error(
				<div>
					Your message has no copy or body for the message.
					<br />
					<br />
					Please enter message copy/text prior to clicking the Post Message
					button.
					<br />
					&nbsp;
				</div>
			);
			return;
		}

		// Reset validation message if the form is correctly filled
		setValidationMessage("");
		const formData = {
			send_to_users: sendToUser,
			group_send: sendToGroup,
			mess_type: sendViaEmail ? 1 : 0,
			mess_subject: subject,
			mess_timestamp: Math.floor(Date.now() / 1000), // Current epoch time
			mess_body: editorContent,
			parent_id: 0,
			urgency: urgency,
			mess_read: 0,
			mess_sent_by: 0,
			mess_booking: 0,
			mess_deleted: 0,
			mess_grouping: mess_grouping,
		};

		try {
			const response = await fetch("https://nrgwappdata.com/saveMessage", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (!response.ok) {
				const errorText = await response.text(); // Get the response text
				throw new Error(errorText || "Network response was not ok");
			}

			const data = await response.json(); // This assumes the response is OK and is JSON
			toast.success("Message saved successfully.");
			resetForm(); // Reset the form after successful submission
		} catch (error) {
			console.error("Submission error:", error);
			toast.error(`An error occurred. ${error.message}`);
		}
	};

	const isSubmitDisabled =
		!subject.trim() || (sendToGroup.length === 0 && sendToUser.length === 0);

	return (
		<div className="message-form-container">
			<Box
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
				sx={{ width: "95%", margin: "0 auto" }}
			>
				{/* Subject/Title and Urgency in one row */}
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", sm: "row" },
						gap: 2,
						marginBottom: 2,
					}}
				>
					{/* Subject/Title TextField */}
					<TextField
						label="Subject/Title"
						variant="outlined"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						fullWidth
						name="mess_subject"
						error={errors.subject}
						helperText={errors.subject ? "Subject is required." : ""}
						sx={{ flex: 1 }}
					/>
					{/* Urgency RadioGroup */}
					<FormControl
						sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
					>
						<RadioGroup
							row
							aria-label="urgency"
							name="urgency"
							value={urgency}
							onChange={(e) => setUrgency(e.target.value)}
						>
							<FormControlLabel value="0" control={<Radio />} label="Normal" />
							<FormControlLabel
								value="1"
								control={<Radio />}
								label="Important"
							/>
							<FormControlLabel value="2" control={<Radio />} label="Urgent" />
						</RadioGroup>
					</FormControl>
				</Box>

				<Editor value={editorContent} onChange={setEditorContent} />

				{/* Send To Group on its own row */}
				<FormControl fullWidth sx={{ marginBottom: 2 }}>
					<InputLabel>Send To Group</InputLabel>
					<Select
						multiple
						value={sendToGroup}
						onChange={(e) => setSendToGroup(e.target.value)}
						variant="outlined"
					>
						{groups.map((group) => (
							<MenuItem key={group.groupID} value={group.groupID}>
								{group.group_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{errors.recipient && (
					<p style={{ color: "red" }}>Select at least one group or user.</p>
				)}
				{/* Send To User on its own row */}
				<FormControl fullWidth sx={{ marginBottom: 2 }}>
					<InputLabel>Send To User</InputLabel>
					<Select
						multiple
						value={sendToUser}
						onChange={(e) => setSendToUser(e.target.value)}
						variant="outlined"
					>
						{users.map((user) => (
							<MenuItem key={user.uid} value={user.uid}>
								{user.contractor_full_name} - {user.company}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* Send Via Email and Post Message button on the same row */}
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", sm: "row" },
						gap: 2,
						justifyContent: "space-between",
					}}
				>
					<FormGroup sx={{ flex: 1 }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={sendViaEmail}
									onChange={(e) => setSendViaEmail(e.target.checked)}
									name="sendViaEmail"
								/>
							}
							label="Send VIA Email As Well"
						/>
					</FormGroup>
					<Button type="submit" variant="contained" sx={{ flex: 1 }}>
						POST MESSAGE
					</Button>
				</Box>
			</Box>
		</div>
	);
};

export default MessageForm;
