import React, { useRef, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider"; // Import AuthContext
import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";
import CellPhone from "../../assets/images/nrgw-cell-phoneb-6622abf94fcb3.webp";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import "./Login.scss";

const LOGIN_URL = "https://nrgwappdata.com/login";
//const LOGIN_URL = "localhost:3001/login";

const Login = () => {
	const { authState } = useContext(AuthContext);
	const navigate = useNavigate();
	const { login } = useContext(AuthContext);

	const errRef = useRef();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [errors, setErrors] = useState({});

	const [openAndroidModal, setOpenAndroidModal] = useState(false);
	const [openAppleModal, setOpenAppleModal] = useState(false);

	const handleOpenAndroidModal = () => setOpenAndroidModal(true);
	const handleCloseAndroidModal = () => setOpenAndroidModal(false);

	const handleOpenAppleModal = () => setOpenAppleModal(true);
	const handleCloseAppleModal = () => setOpenAppleModal(false);

	const validate = () => {
		let tempErrors = {};
		if (!email) {
			tempErrors.email = "User Name is required";
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			tempErrors.email = "User Name is in incorrect format"; // Generic error message
		} else {
			tempErrors.email = "";
		}

		tempErrors.password = password ? "" : "Password is required";

		setErrors(tempErrors);
		return Object.values(tempErrors).every((x) => x === "");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("made it to here");
		if (!validate()) return;
		console.log("made it to there");
		try {
			console.log("IN THE TRY");
			const response = await login(email, password); // This should handle setting the auth state
			// if (authState.additionalUserInfo?.accessLevel === 3025) {
			// 	navigate("/mydashboard");
			// } else {
			// 	navigate("/dashboard");
			// }
		} catch (error) {
			console.log("In The Error");
			console.error("Login error:", error);
			if (error.response && error.response.status === 401) {
				setErrors((prev) => ({
					...prev,
					form: "Login failed. Please try again.",
				}));
			} else {
				setErrors((prev) => ({
					...prev,
					form: "Login failed. Please try again.",
				}));
			}
		}
		setEmail("");
		setPassword("");
	};
	useEffect(() => {
		const hasAccessLevel3025 =
			authState.additionalUserInfo?.accessLevel === 3025;

		if (hasAccessLevel3025) {
			navigate("/mydashboard");
		} else if (authState?.isAuthenticated) {
			navigate("/dashboard");
		}
	}, [authState, navigate]);
	return (
		<>
			<div className="loginContainer">
				<div className="login">
					<div className="leftSide">
						<div className="logo">
							<img src={CompanyLogo} alt="NRGWise" className="logoImage" />
						</div>
						<div className="loginText">
							<h2 className="loginHeader">
								Helping Deliver Peace of Mind for over 20 years.
							</h2>
							<div className="italics">
								Since the late 1990s, we have helped home buyers and sellers
								gain Peace of Mind in their real estate transactions and helped
								Independent Energy Advisors in their delivery of home energy
								assessments. We have had the privilege of participating in the
								training of over 160 Energy Advisors in Canada.
							</div>
						</div>
					</div>
					<div className="rightSide">
						<img src={CellPhone} alt="NRGWise" className="cellPhone" />
					</div>
				</div>
				<div className="loginArea">
					<hr className="horizLine" />
					<div className="instructionsSection">
						<span style={{ color: "#969090" }}>Instructions: </span>
						<IconButton onClick={handleOpenAndroidModal}>
							<AndroidIcon fontSize="medium" sx={{ color: "green" }} />
						</IconButton>
						<Modal
							open={openAndroidModal}
							onClose={handleCloseAndroidModal}
							aria-labelledby="android-modal-title"
							aria-describedby="android-modal-description"
						>
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									width: "40vw", // Default width for desktop
									maxWidth: "90vw", // Limit maximum width to 90% of viewport width
									maxHeight: "70vh", // Limit modal height to 70% of screen height
									overflowY: "auto", // Enable vertical scrolling if content overflows
									bgcolor: "background.paper",
									boxShadow: 24,
									p: 4,
									"@media (max-width: 600px)": {
										width: "75vw", // Change width to 75vw for mobile devices
									},
								}}
							>
								<Typography
									id="android-modal-title"
									variant="h6"
									component="h2"
								>
									Android Instructions
								</Typography>
								<Typography id="android-modal-description" sx={{ mt: 2 }}>
									<p>
										<ol>
											<li>
												<strong>Open the Browser:</strong> Launch the web
												browser on your Android device. PWAs are designed to
												work on modern browsers like Chrome, Firefox, or Edge.{" "}
											</li>
											<li>
												<strong>Visit the PWA:</strong> Navigate to the website
												hosting the PWA. You can do this by typing the URL
												directly into the browser's address bar.{" "}
											</li>
											<li>
												<strong>Add to Home Screen:</strong> Once you are on the
												PWA website, look for an option in the browser's menu
												(usually accessible via three dots in the top-right
												corner) that says "Add to Home Screen" or something
												similar. Select this option.{" "}
											</li>
											<li>
												<strong>Add to Home Screen Prompt:</strong> A prompt
												will appear asking if you want to add the website to
												your home screen. Confirm by tapping "Add" or "Add to
												Home Screen".{" "}
											</li>
											<li>
												<strong>PWA Icon:</strong> The PWA will now be added to
												your device's home screen, usually with an icon that
												represents the website or app. Access the PWA: You can
												now access the PWA just like any other app on your
												device. Tap the icon on your home screen to launch the
												PWA in a standalone window, separate from the browser.{" "}
											</li>
											<li>
												<strong>Offline Access:</strong> PWAs are designed to
												work offline or with a poor internet connection. You
												should be able to use the PWA even when you're not
												connected to the internet.{" "}
											</li>
											<li>
												<strong>Updates:</strong> PWAs are updated automatically
												in the background, ensuring you always have the latest
												version of the app.
											</li>
										</ol>
									</p>
								</Typography>
							</Box>
						</Modal>
						<IconButton onClick={handleOpenAppleModal}>
							<AppleIcon fontSize="medium" sx={{ color: "blue" }} />
						</IconButton>
						<Modal
							open={openAppleModal}
							onClose={handleCloseAppleModal}
							aria-labelledby="apple-modal-title"
							aria-describedby="apple-modal-description"
						>
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									width: "40vw", // Default width for desktop
									maxWidth: "90vw", // Limit maximum width to 90% of viewport width
									maxHeight: "70vh", // Limit modal height to 70% of screen height
									overflowY: "auto", // Enable vertical scrolling if content overflows
									bgcolor: "background.paper",
									boxShadow: 24,
									p: 4,
									"@media (max-width: 600px)": {
										width: "75vw", // Change width to 75vw for mobile devices
									},
								}}
							>
								<Typography id="apple-modal-title" variant="h6" component="h2">
									iOS Instructions
								</Typography>
								<Typography id="apple-modal-description" sx={{ mt: 2 }}>
									<p>
										<ol>
											<li>
												<strong>Open Safari:</strong> Launch the Safari web
												browser on your iPhone or iPad.{" "}
											</li>
											<li>
												<strong>Visit the PWA:</strong> Navigate to the website
												hosting the PWA. You can do this by typing the URL
												directly into Safari's address bar.{" "}
											</li>
											<li>
												<strong>Add to Home Screen:</strong> Tap the "Share"
												icon at the bottom of the Safari window. It looks like a
												square with an arrow pointing up.{" "}
											</li>
											<li>
												<strong>Add to Home Screen:</strong> In the Share menu,
												scroll down and select "Add to Home Screen."
											</li>

											<li>
												<strong>Customize the Name:</strong> You can customize
												the name of the app if you wish.{" "}
											</li>
											<li>
												<strong>Add to Home Screen:</strong> Tap "Add" in the
												top-right corner of the screen.{" "}
											</li>
											<li>
												<strong>Access the PWA:</strong> The PWA will now be
												added to your device's home screen as an app icon. Tap
												the icon to launch the PWA in standalone mode, separate
												from the Safari browser.
											</li>
											<li>
												<strong>Use the PWA:</strong> You can now use the PWA
												like a native app on your device. It will work offline
												and have access to device features if the PWA is
												designed to support them.{" "}
											</li>
											<li>
												<strong>Updates:</strong> PWAs are updated automatically
												when you're connected to the internet, ensuring you
												always have the latest version of the app.
											</li>
										</ol>
									</p>
								</Typography>
							</Box>
						</Modal>
					</div>
					<div className="loginSection">
						<h3>
							User Login <LockPersonOutlinedIcon />
						</h3>
						{errors.form && (
							<p ref={errRef} className="errmsg" aria-live="assertive">
								{errors.form}
							</p>
						)}
						<form onSubmit={handleSubmit} className="loginForm">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										id="email"
										name="email"
										label="User Name"
										type="text"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										fullWidth
										error={!!errors.email}
										helperText={errors.email || ""}
										sx={{ padding: "10px" }}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="password"
										name="password"
										label="Password"
										type="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										fullWidth
										error={!!errors.password}
										helperText={errors.password || ""}
										sx={{ padding: "10px" }}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								style={{ marginTop: "20px" }}
							>
								Login
							</Button>
						</form>
					</div>
					<div className="loginSection">
						<span className="registerNow">
							Don't have an account yet?
							<br />
							<NavLink to="/register">Click here to register now</NavLink>
						</span>
					</div>
				</div>
				<div className="footer">
					<p>&copy;2023 NRG Wise. All rights reserved.</p>
					<p>&nbsp; </p>
				</div>
			</div>
		</>
	);
};
export default Login;
