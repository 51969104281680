import React, { useState } from "react";
import {
	Grid,
	TextField,
	Button,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormHelperText,
} from "@mui/material";
import axios from "axios";
import "./Register.scss";

const MaterialFormComponent = () => {
	const initialValues = {
		company: "",
		contractor_full_name: "",
		add1: "",
		add2: "",
		city: "",
		prov: "",
		postal: "",
		cell: "",
		alt_phone: "",
		email: "",
		u_pass: "",
		confirm_pass: "",
	};

	const [formValues, setFormValues] = useState(initialValues);
	const [errors, setErrors] = useState({});
	const [successMessage, setSuccessMessage] = useState("");

	const validate = () => {
		let tempErrors = {};
		tempErrors.contractor_full_name = formValues.contractor_full_name
			? ""
			: "This field is required.";
		tempErrors.email = formValues.email ? "" : "This field is required.";
		tempErrors.city = formValues.city ? "" : "This field is required.";
		tempErrors.prov = formValues.prov ? "" : "This field is required.";
		tempErrors.postal = formValues.postal ? "" : "This field is required.";
		tempErrors.cell = formValues.cell ? "" : "This field is required.";
		tempErrors.u_pass = formValues.u_pass ? "" : "This field is required.";
		tempErrors.confirm_pass = formValues.confirm_pass
			? ""
			: "This field is required.";
		if (formValues.u_pass !== formValues.confirm_pass) {
			tempErrors.confirm_pass = "Passwords do not match.";
		}
		setErrors(tempErrors);
		return Object.values(tempErrors).every((x) => x === "");
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validate()) return;

		try {
			const { confirm_pass, ...userData } = formValues;
			const response = await axios.post(
				"https://nrgwappdata.com/register",
				userData
			);
			// Clear form and set success message
			setFormValues(initialValues);
			setSuccessMessage(
				"Your registration has been successful. Someone will look at activating your account in the next 48 hours."
			);
			// Handle further actions
		} catch (error) {
			console.error("Registration error:", error);
			// Handle error
		}
	};

	return (
		<div className="scrollable-container">
			{successMessage && (
				<div className="success-message">{successMessage}</div>
			)}
			<form onSubmit={handleSubmit}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={12}>
						<h3>Contractor Registration</h3>
						<span className="small red">
							All fields marked with an * are required.
						</span>{" "}
					</Grid>

					<Grid item xs={12}>
						<TextField
							id="company"
							name="company"
							label="Company Name"
							type="text"
							value={formValues.company}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Grid item xs={12} md={6}>
							<TextField
								id="contractor_full_name"
								name="contractor_full_name"
								label="Your Full Name *"
								type="text"
								value={formValues.contractor_full_name}
								onChange={handleInputChange}
								fullWidth
								sx={{ padding: "10px" }}
								error={!!errors.contractor_full_name}
								helperText={errors.contractor_full_name}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							id="email"
							name="email"
							label="Email Address *"
							type="text"
							value={formValues.email}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.email}
							helperText={errors.email}
						/>
					</Grid>

					<Grid item xs={12} md={4}>
						<TextField
							id="city"
							name="city"
							label="City *"
							type="text"
							value={formValues.city}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.city}
							helperText={errors.city}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.prov}
						>
							<InputLabel id="prov_label">Province/Territory *</InputLabel>
							<Select
								labelId="prov_label"
								id="prov"
								name="prov"
								value={formValues.prov}
								label="Province/Territory *"
								onChange={handleInputChange}
								sx={{ height: "45px", padding: "10px" }}
							>
								<MenuItem key="ab" value="AB">
									Alberta
								</MenuItem>
								<MenuItem key="bc" value="BC">
									British Columbia
								</MenuItem>
								<MenuItem key="mb" value="MB">
									Manitoba
								</MenuItem>
								<MenuItem key="nb" value="NB">
									New Brunswick
								</MenuItem>
								<MenuItem key="nl" value="NL">
									Newfoundland & Labrador
								</MenuItem>
								<MenuItem key="nt" value="NT">
									Northwest Territories
								</MenuItem>
								<MenuItem key="ns" value="NS">
									Nova Scotia
								</MenuItem>
								<MenuItem key="nu" value="NU">
									Nunavut
								</MenuItem>
								<MenuItem key="on" value="ON">
									Ontario
								</MenuItem>
								<MenuItem key="pe" value="PE">
									Prince Edward island
								</MenuItem>
								<MenuItem key="qc" value="QC">
									Quebec
								</MenuItem>
								<MenuItem key="sk" value="SK">
									Saskatchewan
								</MenuItem>
								<MenuItem key="yt" value="YT">
									Yukon
								</MenuItem>
							</Select>
							{errors.prov && <FormHelperText>{errors.prov}</FormHelperText>}
						</FormControl>
					</Grid>
					<Grid item xs={12} md={2}>
						<TextField
							id="postal"
							name="postal"
							label="Postal *"
							type="text"
							value={formValues.postal}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.postal}
							helperText={errors.postal}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							id="cell"
							name="cell"
							label="Contact Number *"
							type="text"
							value={formValues.cell}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.cell}
							helperText={errors.cell}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							id="alt_phone"
							name="alt_phone"
							label="Alternate Contact Number"
							type="text"
							value={formValues.alt_phone}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							id="u_pass"
							name="u_pass"
							placeholder="Password *"
							type="password"
							value={formValues.u_pass}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.u_pass}
							helperText={errors.u_pass}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							id="confirm_pass"
							name="confirm_pass"
							placeholder="Confirm Password *"
							type="password"
							value={formValues.confirm_pass}
							onChange={handleInputChange}
							fullWidth
							sx={{ padding: "10px" }}
							error={!!errors.confirm_pass}
							helperText={errors.confirm_pass}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						fullWidth
						style={{
							backgroundColor: "green",
							marginTop: "25px",
							marginBottom: "25px",
						}}
					>
						Register Now
					</Button>
				</Grid>
			</form>
		</div>
	);
};

export default MaterialFormComponent;
