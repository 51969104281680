import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext"; // Import TabContext

import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/NavBar";
import AllBookings from "../../components/bookings/BookingsIndex";
import ContactBooking from "../../components/bookings/NotContacted";
import FailedBooking from "../../components/bookings/FailedCancelled";
import NotBooked from "../../components/bookings/NotBooked";
import Booked from "../../components/bookings/Booked";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "./OfficeBookings.scss";

const Bookings = () => {
	const [value, setValue] = useState("1");
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<TabContext value={value}>
			<div className="bookings">
				<Sidebar />

				<div className="bookingsContainer">
					<h3>Booking Requests</h3>

					<div className="allBookings">
						<Box sx={{ width: "100%" }}>
							<Tabs
								value={value}
								onChange={handleChange}
								textColor="secondary"
								indicatorColor="secondary"
								aria-label="secondary tabs example"
							>
								<Tab value="1" label="Need To Contact Request" />
								<Tab value="2" label="Contacted Not Booked" />
								<Tab value="3" label="Booked Requests" />
								<Tab value="4" label="View All Requests" />
								<Tab value="5" label="Cancelled Requests" />
							</Tabs>
						</Box>
						<TabPanel value="1">
							<ContactBooking />
						</TabPanel>
						<TabPanel value="2">
							<NotBooked />
						</TabPanel>
						<TabPanel value="3">
							<Booked />
						</TabPanel>
						<TabPanel value="4">
							<AllBookings />
						</TabPanel>
						<TabPanel value="5">
							<FailedBooking />
						</TabPanel>
					</div>
				</div>
			</div>
		</TabContext>
	);
};

export default Bookings;
