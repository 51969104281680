import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";
import {
	Grid,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextField,
	MenuItem,
	Select,
	FormGroup,
	Checkbox,
	Button,
	InputLabel,
} from "@mui/material";

import { AuthContext } from "../../context/AuthProvider";

import MyNavbar from "../../pages/dashboard/MyNavbar";

import "./NewBooking.scss";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/requests";

function NewBooking() {
	const [formData, setFormData] = useState({
		book_full_name: "",
		book_email: "",
		book_phone: "",
		book_add_1: "",
		book_add_2: "",
		book_alt_phone: "",
		book_city: "",
		book_prov: "",
		book_postal: "",
		book_other: "",
		prefer_agent_1: "",
		prefer_agent_2: "",
		upgrades: {
			A: false,
			B: false,
			C: false,
			D: false,
			E: false,
			F: false,
		},
	});
	const [errors, setErrors] = useState({});
	const { authState } = useContext(AuthContext);
	const userId = authState.user?.id;
	const getCheckedUpgrades = () => {
		return Object.entries(formData.upgrades)
			.filter(([key, value]) => value)
			.map(([key]) => key)
			.join(",");
	};

	const validateForm = () => {
		let tempErrors = {};
		let isValid = true;

		// Check for Full Name
		if (!formData.book_full_name) {
			tempErrors.book_full_name = "Full Name is required";
			isValid = false;
		}

		// Check for Email Address
		if (!formData.book_email) {
			tempErrors.book_email = "Email Address is required";
			isValid = false;
		} else if (!/\S+@\S+\.\S+/.test(formData.book_email)) {
			tempErrors.book_email = "Email Address is invalid";
			isValid = false;
		}
		// Check for Contact Number
		if (!formData.book_phone) {
			tempErrors.book_phone = "Contact Number is required";
			isValid = false;
		}

		// Check for Address
		if (!formData.book_add_1) {
			tempErrors.book_add_1 = "Address is required";
			isValid = false;
		}

		// Check for City
		if (!formData.book_city) {
			tempErrors.book_city = "City is required";
			isValid = false;
		}

		// Check for Province
		if (!formData.book_prov) {
			tempErrors.book_prov = "Province is required";
			isValid = false;
		}

		// Check for Postal Code
		if (!formData.book_postal) {
			tempErrors.book_postal = "Postal Code is required";
			isValid = false;
		}

		// Additional check for "book_other" if "F" is checked
		if (formData.upgrades["F"] && !formData.book_other) {
			tempErrors.book_other = "This field is required when 'Other' is selected";
			isValid = false;
		}
		setErrors(tempErrors);
		return isValid;
	};

	const handleInputChange = (event) => {
		const { name, value, checked, type } = event.target;

		// Update state for checkboxes
		if (type === "checkbox") {
			setFormData((prevState) => ({
				...prevState,
				upgrades: { ...prevState.upgrades, [name]: checked },
			}));

			// If "F" is unchecked, clear any error messages for book_other
			if (name === "F" && !checked) {
				setErrors((prevErrors) => ({ ...prevErrors, book_other: "" }));
			}
		} else {
			// Update state for other input fields
			setFormData((prevState) => ({ ...prevState, [name]: value }));

			// Additionally, if the input field being updated is 'book_other',
			// clear its error message as the user is actively changing it
			if (name === "book_other") {
				setErrors((prevErrors) => ({ ...prevErrors, book_other: "" }));
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (validateForm()) {
			const book_planned = getCheckedUpgrades();
			const book_added = Math.floor(Date.now() / 1000); // Epoch date now

			const submissionData = {
				...formData,
				book_owner: userId,
				book_added,
				book_planned,
				book_attempts: 0,
			};
			console.log(submissionData);
			axios
				.post(`https://nrgwappdata.com/newbooking`, submissionData, {
					withCredentials: true,
				})
				.then((response) => {
					// Check if the response indicates success
					if (response.status === 200) {
						// Reset the form
						document.getElementById("frmNewBook").reset();
					}
				})
				.catch((error) => {
					// Handle errors here
					console.error(error);
				});
		}
	};

	const isOtherChecked = formData.upgrades["F"];

	return (
		<>
			<div className="myNewBookingContainer">
				<div className="myNewBookingHeader">
					<img
						src={CompanyLogo}
						alt="NRGWise Logo"
						className="logoImageNewBooking"
					/>
				</div>
				<MyNavbar />
				<div className="newBooking">
					<div className="headerMessageCopy">
						<span className="messageStatusText">Add&nbsp;New&nbsp;Request</span>
					</div>
					<form onSubmit={handleSubmit} id="frmNewBook">
						<Grid container alignItems="center" spacing={1} padding={"10px"}>
							<Grid item xs={12}>
								<TextField
									error={!!errors.book_full_name}
									helperText={errors.book_full_name}
									id="book_full_name"
									name="book_full_name"
									label="Full Name"
									type="text"
									value={formData.book_full_name}
									onChange={handleInputChange}
									fullWidth
									size="small"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									error={!!errors.book_email}
									helperText={errors.book_email}
									id="book_email"
									name="book_email"
									label="Email Address"
									type="text"
									value={formData.book_email}
									onChange={handleInputChange}
									fullWidth
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={!!errors.book_phone}
									helperText={errors.book_phone}
									id="book_phone"
									name="book_phone"
									label="Contact Number"
									type="text"
									size="small"
									fullWidth
									value={formData.book_phone}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="book_alt_phone"
									name="book_alt_phone"
									label="Alternate Contact Number"
									size="small"
									fullWidth
									value={formData.book_alt_phone}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									error={!!errors.book_add_1}
									helperText={errors.book_add_1}
									id="book_add_1"
									name="book_add_1"
									label="Address"
									fullWidth
									size="small"
									value={formData.book_add_1}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="book_add_2"
									name="book_add_2"
									fullWidth
									size="small"
									value={formData.book_add_2}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									error={!!errors.book_city}
									helperText={errors.book_city}
									id="book_city"
									name="book_city"
									label="City"
									fullWidth
									size="small"
									value={formData.book_city}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									error={!!errors.book_prov}
									helperText={errors.book_prov}
									id="book_prov"
									name="book_prov"
									label="Province"
									fullWidth
									size="small"
									value={formData.book_prov}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									error={!!errors.book_postal}
									helperText={errors.book_postal}
									id="book_postal"
									name="book_postal"
									label="Postal"
									fullWidth
									size="small"
									value={formData.book_postal}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<br />
								PLANNED UPGRADES<hr></hr>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											name="A"
											checked={formData.upgrades.A}
											onChange={handleInputChange}
										/>
									}
									label="Air Source Heat Pump"
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											name="B"
											checked={formData.upgrades.B}
											onChange={handleInputChange}
										/>
									}
									label={<>Domestic Hot Water ASHP</>}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											name="C"
											checked={formData.upgrades.C}
											onChange={handleInputChange}
										/>
									}
									label={<>Windows/Doors</>}
								/>
							</Grid>

							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											name="D"
											checked={formData.upgrades.D}
											onChange={handleInputChange}
										/>
									}
									label={<>Insulation</>}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											name="E"
											checked={formData.upgrades.E}
											onChange={handleInputChange}
										/>
									}
									label={<>Solar</>}
								/>
							</Grid>

							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											name="F"
											checked={isOtherChecked}
											onChange={handleInputChange}
										/>
									}
									label="Other"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={!!errors.book_other}
									helperText={errors.book_other}
									id="book_other"
									name="book_other"
									label=""
									fullWidth
									size="small"
									value={formData.book_other}
									onChange={handleInputChange}
									disabled={!isOtherChecked}
								/>
								<input type="hidden" name="book_owner" value={userId} />
								<br />
								&nbsp;
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="prefer_agent_1"
									name="prefer_agent_1"
									label="Preferred Energy Advisor 1"
									type="text"
									size="small"
									fullWidth
									value={formData.prefer_agent_1}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									id="prefer_agent_2"
									name="prefer_agent_2"
									label="Preferred Energy Advisor 2"
									type="text"
									size="small"
									fullWidth
									value={formData.prefer_agent_2}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								alignItems={"flex-end"}
								container
								justifyContent="flex-end"
								paddingRight={"20px"}
								marginTop={"20px"}
							>
								<Button type="submit" variant="contained">
									Submit New Request
								</Button>
							</Grid>
						</Grid>
					</form>
				</div>
				<div className="footer">
					<p>&copy;2023 NRG Wise. All rights reserved.</p>
					<p>&nbsp; </p>
				</div>
			</div>
		</>
	);
}

export default NewBooking;
