import React, { useState, useEffect } from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	Snackbar,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestoreIcon from "@mui/icons-material/Restore";
import MuiAlert from "@mui/material/Alert";
import "./DeletedUsers.scss";

const DeletedUsers = () => {
	const [users, setUsers] = useState([]);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
	const [selectedUserForRestore, setSelectedUserForRestore] = useState(null);

	useEffect(() => {
		fetchDeletedUsers();
	}, []);

	const fetchDeletedUsers = async () => {
		try {
			const response = await fetch("https://nrgwappdata.com/deletedUsers"); // Adjust this endpoint as necessary
			if (!response.ok) {
				throw new Error("Failed to fetch deleted users");
			}
			const data = await response.json();
			setUsers(data);
		} catch (error) {
			console.error("Error fetching deleted users:", error);
		}
	};

	const handleRestoreUser = async () => {
		try {
			// Assuming your backend expects a PUT request to restore a user
			// and the user's ID is sent as a URL parameter
			const response = await axios.post(
				`https://nrgwappdata.com/restoreUser/${selectedUserForRestore}`
			);

			// Assuming the backend sends back a success response
			console.log("Restore User Response:", response.data);

			// Close the dialog after successful restore
			setOpenRestoreDialog(false);

			// Show the snackbar for user feedback
			setOpenSnackbar(true);

			// Refresh or re-fetch the list of deleted users
			fetchDeletedUsers();
		} catch (error) {
			console.error("Error restoring user:", error);
			// Handle error, e.g., show an error message to the user
		}
	};

	const handleOpenRestoreDialog = (userId) => {
		setSelectedUserForRestore(userId); // Store the ID of the user to restore
		setOpenRestoreDialog(true); // Open the dialog
	};

	const handleCloseDialog = () => {
		setOpenRestoreDialog(false);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	// // Snackbar Alert Component
	// const Alert = React.forwardRef(function Alert(props, ref) {
	// 	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	// });

	return (
		<div className="usersContainer" style={{ marginTop: "20px" }}>
			{users.length > 0 ? (
				users.map((user) => (
					<Accordion
						key={user.uid}
						sx={{
							width: "95%", // Maintain width
							margin: "0 auto", // Center the accordion
							marginBottom: "10px", // Margin bottom for spacing
							"&.Mui-expanded": {
								// Target the expanded state
								margin: "0 auto", // Center the accordion when expanded
								maxHeight: "none", // Prevent accordion from expanding
								marginBottom: "10px",
							},
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel-user-content-${user.uid}`}
							id={`panel-user-header-${user.uid}`}
							sx={{
								justifyContent: "center", // Center the content horizontally
								alignItems: "center", // Center the content vertically
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
								<Typography>{user.contractor_full_name} - &nbsp;</Typography>
								<Typography
									sx={{ fontSize: "10pt", position: "relative", top: "2px" }}
								>
									Email: {user.email}
								</Typography>
							</Box>
							<IconButton
								onClick={() => handleOpenRestoreDialog(user.uid)}
								aria-label="restore"
								sx={{ marginRight: "8px" }}
							>
								<RestoreIcon />
							</IconButton>
							<IconButton aria-label="delete">
								<DeleteIcon />
							</IconButton>
						</AccordionSummary>
						<AccordionDetails
							sx={{
								backgroundColor: "white",
							}}
						>
							<Typography>Company: {user.company}</Typography>
							<Typography>Address 1: {user.add_1}</Typography>
							<Typography>Address 2: {user.add_2}</Typography>
							<Typography>City: {user.city}</Typography>
							<Typography>Province: {user.prov}</Typography>
							<Typography>Postal Code: {user.postal}</Typography>
							<Typography>Cell Phone: {user.cell}</Typography>
							<Typography>Alternate Phone: {user.alt_phone}</Typography>
						</AccordionDetails>
					</Accordion>
				))
			) : (
				<Typography sx={{ textAlign: "center", mt: 2 }}>
					<h3>Currently, no deleted users can be found in the system.</h3>
				</Typography>
			)}
			<Dialog open={openRestoreDialog} onClose={handleCloseDialog}>
				<DialogTitle>Restore this user?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to restore the selected user?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>Cancel</Button>
					<Button onClick={handleRestoreUser} color="primary" autoFocus>
						Restore
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={handleSnackbarClose}
					severity="success"
				>
					User restored successfully.
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default DeletedUsers;
