import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
// import Navbar from "../../components/navbar";
// import { useNavigate } from "react-router-dom";
import UserForm from "../../components/adduser/AddUser";

import "./UsersStyles.scss";

const AddUser = () => {
	return (
		<div className="addusers">
			<Sidebar />
			<div className="addusersContainer">
				{/* <Navbar /> */}
				<h3>Add User</h3>
				<div className="users">
					<div className="usersContainer">
						<UserForm />
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddUser;
