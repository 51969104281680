import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";

// ICONS FOR DASHBOARD
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MessageIcon from "@mui/icons-material/Message";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// Logo
import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";

import { NavLink } from "react-router-dom";

import "./SideBar.scss";

const SideBar = () => {
	const { logout } = useContext(AuthContext);
	const navigate = useNavigate();

	const handleLogout = () => {
		logout(); // Call the logout function to clear auth state
		navigate("/"); // Redirect to the login page
	};
	return (
		<div className="sidebar">
			<div className="top">
				<NavLink to="/dashboard">
					<img src={CompanyLogo} alt="NRGWise" className="logo" />
				</NavLink>
			</div>
			<div className="center">
				<ul>
					<p className="title">Main</p>
					<li>
						<DashboardIcon className="icon" />
						<span>
							<NavLink to="/dashboard">Dashboard</NavLink>
						</span>
					</li>
					{/* <li>
						<LegendToggleIcon className="icon" />
						<span>
							<NavLink to="/analytics">Analytics</NavLink>
						</span>
					</li> */}
					<p className="title">Bookings/Messaging</p>
					<li>
						<MenuBookIcon className="icon" />
						<span>
							<NavLink to="/requests">Bookings</NavLink>
						</span>
					</li>
					<li>
						<MessageIcon className="icon" />
						<span>
							<NavLink to="/messaging">Messaging</NavLink>
						</span>
					</li>
					<p className="title">Users</p>
					<li>
						<PeopleAltIcon className="icon" />
						<span>
							<NavLink to="/users">View All</NavLink>
						</span>
					</li>
					<li>
						<PersonAddIcon className="icon" />
						<span>
							<NavLink to="/users/adduser">Add New</NavLink>
						</span>
					</li>
					<p className="title">Settings</p>
					<li>
						<SettingsSuggestIcon className="icon" />
						<span>
							<NavLink to="/settings">Settings</NavLink>
						</span>
					</li>
					<li>
						<AccountCircleIcon className="icon" />
						<span>
							<NavLink to="/profile">Profile</NavLink>
						</span>
					</li>
					<p>&nbsp; </p>
					<p>&nbsp; </p>
					<li onClick={handleLogout}>
						<LogoutIcon className="icon" />
						<span>Logout</span>
					</li>
				</ul>
			</div>

			<div className="bottom">
				{/* <div className="colorOption"></div>
        <div className="colorOption"></div> */}
			</div>
		</div>
	);
};

export default SideBar;
