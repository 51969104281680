import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Snackbar,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Sidebar from "../../components/sidebar/SideBar";
import { AuthContext } from "../../context/AuthProvider";

import "./UsersStyles.scss";
const Profile = () => {
	const [user, setUser] = useState({
		company: "",
		add_1: "",
		add_2: "",
		city: "",
		prov: "ON",
		postal: "",
		contractor_full_name: "",
		cell: "",
		email: "",
		alt_phone: "",
		active: 0, // Ensure active is initialized as a string or number, not boolean
		deleted: 0, // Ensure deleted is initialized as a string or number, not boolean
		access_level: 2048, // Ensure access_level is initialized as a string or number
	});

	const { authState } = useContext(AuthContext);

	// Access the user ID from the authState
	const userId = authState.user ? authState.user.id : null;

	const [openSnackbar, setOpenSnackbar] = useState(false);
	const navigate = useNavigate();
	const { editID } = useParams();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [errors, setErrors] = useState({
		contractor_full_name: "",
		city: "",
		cell: "",
		email: "",
	});

	const [formChanged, setFormChanged] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`https://nrgwappdata.com/userspecific/${userId}`
				);

				// Assuming the structure is directly the user object
				setUser(response.data);
				console.log("Fetched user data: ==========>  ", response.data); // Add this line
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [userId]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		// Set formChanged to true when any field is changed
		setFormChanged(true);

		// Validate email format for email field
		if (name === "email") {
			const emailRegex = /\S+@\S+\.\S+/;
			if (!emailRegex.test(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "Invalid email address format",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "", // Clear the error message if email format is valid
				}));
			}
		}

		// Validate phone number format for cell and alt_phone fields
		if (name === "cell" || name === "alt_phone") {
			if (value.trim() !== "") {
				if (isValidPhoneNumber(value)) {
					// Clear error message if format is valid
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: "",
					}));
				} else {
					// Show error message for invalid format
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]:
							"Invalid phone number format. Expected format #-###-###-#### (i.e. 1-519-235-6541)",
					}));
				}
			} else {
				// Clear error message if value is empty
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "",
				}));
			}
		}

		// Clear error message for required fields if they pass validation
		if (
			value.trim() !== "" &&
			(name === "contractor_full_name" ||
				name === "city" ||
				name === "cell" ||
				name === "email")
		) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: "",
			}));
		}

		// Update state with new value
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleBlur = (e) => {
		const { name, value } = e.target;

		// Validate email format for email field onBlur
		if (name === "email") {
			const emailRegex = /\S+@\S+\.\S+/;
			if (value && !emailRegex.test(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "Invalid email address format",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: "", // Clear the error message if email format is valid
				}));
			}
		}
	};
	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		// Validation
		let formIsValid = true;
		const newErrors = {
			contractor_full_name: "",
			city: "",
			cell: "",
			email: "",
			password: "",
			confirm_password: "",
		};

		if (!user.contractor_full_name.trim()) {
			newErrors.contractor_full_name = "Full Name is required";
			formIsValid = false;
		}

		if (!user.city.trim()) {
			newErrors.city = "City is required";
			formIsValid = false;
		}

		if (!user.cell.trim()) {
			newErrors.cell = "Main Contact Number is required";
			formIsValid = false;
		}

		if (!user.email.trim()) {
			newErrors.email = "Email Address is required";
			formIsValid = false;
		} else if (!/\S+@\S+\.\S+/.test(user.email)) {
			newErrors.email = "Email Address is invalid";
			formIsValid = false;
		}

		if (user.password !== user.confirm_password) {
			newErrors.confirm_password = "Passwords do not match";
			formIsValid = false;
		}

		setErrors(newErrors);

		if (formIsValid) {
			try {
				// Assuming your API expects the user ID as part of the body
				// If it's expected in the URL, adjust the axios URL accordingly
				const response = await axios.post(
					`https://nrgwappdata.com/updateuser`,
					{
						...user,
						uid: userId,
					}
				);

				console.log(response.data); // For debugging

				// Show success snackbar
				setOpenSnackbar(true);
				// Optionally, navigate the user away or refresh the user data
				// navigate("/users");
			} catch (error) {
				console.error("Error updating user:", error);
				// Optionally, show an error message in the UI
			}
		}
	};

	const handleDelete = async () => {
		try {
			await axios.delete(`https://nrgwappdata.com/users/${editID}`);
			setOpenSnackbar(true);
			navigate("/users"); // Adjust this route as necessary
		} catch (error) {
			console.error("Error deleting user:", error);
		}
	};
	const handleDeleteClick = () => {
		setOpenDeleteDialog(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await axios.post(`https://nrgwappdata.com/deleteUser/${editID}`);
			setOpenSnackbar(true);
			navigate("/users"); // Adjust this route as necessary
		} catch (error) {
			console.error("Error deleting user:", error);
		} finally {
			setOpenDeleteDialog(false); // Close the dialog regardless of the outcome
		}
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	// Snackbar Alert Component
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});
	const now = Date.now();
	const handleCancel = () => {
		navigate(-1); // This will take the user back to the previous page
	};
	const isValidPhoneNumber = (phoneNumber) => {
		// Regular expression to match phone number format "1-###-###-####"
		const phoneRegex = /^1-\d{3}-\d{3}-\d{4}$/;

		return phoneRegex.test(phoneNumber);
	};
	console.log("ID:======================> ", user.uid);
	console.log("Access:======================> ", user.access_level);
	console.log("Active:======================> ", user.active);
	return (
		<div className="profile">
			<Sidebar />
			<div className="profileContainer">
				<h3>My Profile</h3>
				<div className="form_add_user" style={{ margin: "10px" }}>
					<form onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									placeholder="Company working for"
									onChange={handleChange}
									value={user.company || ""}
									name="company"
									style={{ margin: "10px" }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									variant="outlined"
									value={user.contractor_full_name}
									placeholder="Full Name"
									onChange={handleChange}
									name="contractor_full_name"
									style={{ margin: "10px" }}
									error={!!errors.contractor_full_name}
									helperText={errors.contractor_full_name}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									required
									value={user.add_1}
									variant="outlined"
									placeholder="Address"
									onChange={handleChange}
									onBlur={handleBlur}
									name="add_1"
									style={{ margin: "10px" }}
									error={!!errors.add_1}
									helperText={errors.add_1}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									required
									value={user.add_2}
									variant="outlined"
									placeholder="Address line 2"
									onChange={handleChange}
									onBlur={handleBlur}
									name="add_2"
									style={{ margin: "10px" }}
									error={!!errors.add_2}
									helperText={errors.add_2}
								/>
							</Grid>
							{/* City, Prov, Postal with specified widths */}
							<Grid item xs={4} style={{ flexGrow: 1 }}>
								<TextField
									required
									value={user.city}
									variant="outlined"
									placeholder="City"
									onChange={handleChange}
									name="city"
									style={{ margin: "10px" }}
									error={!!errors.city}
									helperText={errors.city}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormControl fullWidth style={{ margin: "10px" }}>
									<Select
										labelId="prov-label"
										onChange={handleChange}
										name="prov"
										value={user.prov}
									>
										<MenuItem value="BC">British Columbia</MenuItem>
										<MenuItem value="MB">Manitoba</MenuItem>
										<MenuItem value="NB">New Brunswick</MenuItem>
										<MenuItem value="NL">Newfoundland and Labrador</MenuItem>
										<MenuItem value="NS">Nova Scotia</MenuItem>
										<MenuItem value="ON">Ontario</MenuItem>
										<MenuItem value="PE">Prince Edward Island</MenuItem>
										<MenuItem value="QC">Quebec</MenuItem>
										<MenuItem value="SK">Saskatchewan</MenuItem>
										<MenuItem value="NT">Northwest Territories</MenuItem>
										<MenuItem value="NU">Nunavut</MenuItem>
										<MenuItem value="YT">Yukon</MenuItem>
										<MenuItem value="BC">British Columbia</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<TextField
									value={user.postal}
									variant="outlined"
									placeholder="Postal/Zip"
									onChange={handleChange}
									name="postal"
									style={{ margin: "10px" }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<TextField
									required
									value={user.email}
									variant="outlined"
									placeholder="Email Address"
									onChange={handleChange}
									onBlur={handleBlur}
									name="email"
									style={{ margin: "10px" }}
									error={!!errors.email}
									helperText={errors.email}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									variant="outlined"
									value={user.cell}
									placeholder="Main Contact Number"
									onChange={handleChange}
									name="cell"
									style={{ margin: "10px" }}
									error={!!errors.cell}
									helperText={errors.cell}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									value={user.alt_phone}
									variant="outlined"
									placeholder="Alternate Contact Number"
									onChange={handleChange}
									name="alt_phone"
									style={{ margin: "10px" }}
									error={!!errors.alt_phone}
									helperText={errors.alt_phone}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth style={{ margin: "10px" }}>
									<Select
										labelId="isactive"
										onChange={handleChange}
										name="active"
										value={user.active}
									>
										<MenuItem value="1">User Is Active</MenuItem>
										<MenuItem value="0">User Is Inactive</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth style={{ margin: "10px" }}>
									<Select
										labelId="access_level"
										onChange={handleChange}
										name="access_level"
										value={user.access_level}
									>
										<MenuItem value="2048">
											Contractor Not Yet Approved
										</MenuItem>
										<MenuItem value="3025">Contractor Approved</MenuItem>
										<MenuItem value="1900">NRGWise Employee</MenuItem>
										<MenuItem value="2382">Administrator</MenuItem>
										<MenuItem value="3001">Super Administrator</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									type="password"
									variant="outlined"
									placeholder="Password"
									onChange={handleChange}
									name="password"
									style={{ margin: "10px" }}
									error={!!errors.password}
									helperText={errors.password}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									required
									type="password"
									variant="outlined"
									placeholder="Confirm Password"
									onChange={handleChange}
									name="confirm_password"
									style={{ margin: "10px" }}
									error={!!errors.confirm_password}
									helperText={errors.confirm_password}
								/>
							</Grid>
							{/* Save and Cancel Buttons */}
							<Grid className="deleteButton" item xs={12} sm={12} md={4}>
								<Button
									fullWidth
									variant="outlined"
									color="primary"
									onClick={handleCancel}
									sx={{ alignSelf: "center" }}
								>
									Cancel
								</Button>
							</Grid>
							<Grid
								className="cancelButton"
								item
								xs={12}
								sm={12}
								md={4}
								sx={{ alignContent: "center" }}
							></Grid>

							<Grid
								className="saveButton"
								item
								xs={12}
								sm={12}
								md={4}
								sx={{ alignContent: "flex-end" }}
							>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									disabled={!formChanged} // Disable button if form has not changed
									sx={{ alignSelf: "flex-end" }}
								>
									Save User Data
								</Button>
							</Grid>
						</Grid>
					</form>

					{/* Snackbar for notifications */}
					<Snackbar
						open={openSnackbar}
						autoHideDuration={6000}
						onClose={handleSnackbarClose}
					>
						<Alert
							onClose={handleSnackbarClose}
							severity="success"
							sx={{ width: "100%" }}
						>
							User successfully deleted.
						</Alert>
					</Snackbar>
					<Dialog
						open={openDeleteDialog}
						onClose={handleCloseDeleteDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Delete"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Are you sure you want to delete this user?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseDeleteDialog}>Cancel</Button>
							<Button onClick={handleConfirmDelete} autoFocus color="error">
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		</div>
	);
};

export default Profile;
