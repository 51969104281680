import React, { useContext } from "react";
import Sidebar from "../../components/sidebar/SideBar";

import Widgets from "../../components/widget/Widget";
import ContactNeeded from "../../components/bookings/NotContacted";

import { AuthContext } from "../../context/AuthProvider";

//STYLES
import "./OfficeDash.scss";

const Dashboard = () => {
	const { authState } = useContext(AuthContext);
	//console.log("GOING IN ====================================================");
	if (authState.isAuthenticated) {
		//	console.log("User's Full Name:", authState.additionalUserInfo.fullName);
		// Access other additional user data similarly
	} else {
		//	console.log("DAMN IT ISNT WORKING");
	}

	return (
		<div className="dashboard">
			<Sidebar />
			<div className="dashboardContainer">
				<h3>Dashboard</h3>
				<hr />
				<h5>Requests that have not been contacted as of yet.</h5>
				<ContactNeeded />
				<hr />

				{/* <div className="widgets">
					<Widgets type="bookToday" />
					<Widgets type="bookYesterday" />
				</div>
				<div className="widgets">
					<Widgets type="tBookToday" />
					<Widgets type="tBookYesterday" />
				</div> */}
			</div>
		</div>
	);
};

export default Dashboard;
