import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupForm from "./GroupForm"; // Adjust the import path as needed

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GroupsManage = () => {
	const [groups, setGroups] = useState([]);
	const [editingGroupId, setEditingGroupId] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedGroupId, setSelectedGroupId] = useState(null);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});

	useEffect(() => {
		fetchGroups();
	}, [editingGroupId]);

	const fetchGroups = () => {
		fetch("https://nrgwappdata.com/appGroups")
			.then((response) => response.json())
			.then((data) => {
				console.log(data); // Log the response data
				setGroups(data);
			})
			.catch((error) => console.error("Failed to fetch groups", error));
	};
	const handleSaveSuccess = () => {
		setEditingGroupId(null);
		fetchGroups();
	};

	const handleCancel = () => {
		setEditingGroupId(null);
	};

	const handleEditClick = (groupId) => {
		setEditingGroupId(groupId);
	};

	const handleDeleteClick = (groupId) => {
		setSelectedGroupId(groupId);
		setOpenDialog(true);
	};

	const deleteGroup = () => {
		fetch(`https://nrgwappdata.com/appGroupsDelete/${selectedGroupId}`, {
			method: "DELETE",
		})
			.then((response) => {
				if (!response.ok) throw new Error("Failed to delete the group");
				return response.json();
			})
			.then(() => {
				fetchGroups();
				//toast.success("Group deleted successfully");
				handleCloseDialog();
			})
			.catch((error) => {
				console.error("Error deleting group", error);
				//toast.error("Failed to delete the group.");
				handleCloseDialog();
			});

		// On successful delete:
		setSnackbar({
			open: true,
			message: "Group deleted successfully",
			severity: "success",
		});
		handleCloseDialog();
		// On failure:
		// setSnackbar({ open: true, message: 'Failed to delete the group.', severity: 'error' });
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setSelectedGroupId(null);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbar({ ...snackbar, open: false });
	};

	return (
		<Box sx={{ margin: "20px" }}>
			{editingGroupId !== null ? (
				<GroupForm
					groupId={editingGroupId}
					onSaveSuccess={handleSaveSuccess}
					onCancel={handleCancel}
				/>
			) : (
				<>
					<Button
						variant="contained"
						onClick={() => setEditingGroupId("new")}
						sx={{ marginBottom: "20px" }}
					>
						Add Group
					</Button>
					<List>
						{groups.map((group) => (
							<ListItem key={group.groupID} divider>
								<ListItemText
									primary={group.group_name}
									secondary={group.group_desc}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="edit"
										onClick={() => handleEditClick(group.groupID)}
									>
										<EditIcon />
									</IconButton>
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={() => handleDeleteClick(group.groupID)}
									>
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</>
			)}
			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this group?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>Cancel</Button>
					<Button onClick={deleteGroup} color="secondary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbar.severity}
					sx={{ width: "100%" }}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default GroupsManage;
