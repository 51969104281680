import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthProvider";
import MyNavbar from "./MyNavbar";
import CompanyLogo from "../../assets/images/Logos/logo-dark-6622ac1ed704a.webp";
import { useNavigate } from "react-router-dom";
import MyBookDash from "../../components/bookings/MyBookDash";

import "./Dashboards.scss";

const MyDashboard = () => {
	const { authState, logout } = useContext(AuthContext);

	const navigate = useNavigate();

	useEffect(() => {
		const userAgent = navigator.userAgent;
		const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);

		if (!isMobile) {
			alert("Please use a mobile device to access this site.");
			logout(); // Assuming `logout` is a function that logs the user out
			navigate("/login"); // Redirect to login page
		}
	}, [logout, navigate]);
	return (
		<>
			<div className="myDashboardContainer">
				<div className="myDashboardHeader">
					<img
						src={CompanyLogo}
						alt="NRGWise Logo"
						className="logoImageMyDash"
					/>
				</div>
				<MyNavbar />
				<MyBookDash />
			</div>
			<div className="footer">
				<p>&copy;2023 NRG Wise. All rights reserved.</p>
				<p>&nbsp; </p>
			</div>
		</>
	);
};

export default MyDashboard;
