import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Modal from "../modals/modalGeneric";
import axios from "axios";

import Box from "@mui/material/Box";
import { CustomNoRowsOverlay } from "../overlays/MyGridOverlays";

const NotBooked = () => {
	const [bookings, setBookings] = useState([]);

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	// Correct the state variable name to match the prop name in Modal
	const [open, setOpen] = useState(false);

	// Add a new state variable to store selected row data
	const [selectedRowData, setSelectedRowData] = useState(null);

	const [bookingRequest, setBookingRequest] = useState({
		bkId: 0,
		bkOwner: 0,
		bkName: "",
		bkPhone: "",
		bkAltPhone: "",
		bkEmail: "",
		bkAdd1: "",
		bkAdd2: "",
		bkCity: "",
		bkProv: "",
		bkPlanned: "",
		bkAttempts: 0,
		bkStatus: 0,
		bkAdded: 0,
		bkBookDate: 0,
		bkAgent1: "",
		bkAgent2: "",
	});

	const formatDate = (dateString) => {
		const date = new Date(dateString);

		if (!isNaN(date.getTime())) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const day = String(date.getDate()).padStart(2, "0");
			const hours = String(date.getHours()).padStart(2, "0");
			const minutes = String(date.getMinutes()).padStart(2, "0");
			return `${year}/${month}/${day} ${hours}:${minutes}`;
		} else {
			// Handle invalid date here
			return "Invalid Date";
		}
	};

	const VISIBLE_FIELDS = [
		"current_status",
		"book_full_name",
		"book_phone",
		"book_add_1",
		"book_owner",
		"book_edit",
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					"https://nrgwappdata.com/bookingsNotBooked"
				);
				setBookings(response.data);
			} catch (error) {
				console.error("Error fetching data:", error);
				setError(error);
			}
		};
		fetchData();
	}, []);

	if (error) {
		return <div>Connectivity issues</div>;
	}

	const getStatusText = (status) => {
		let icoColor;
		let statusText;

		switch (status) {
			case 1:
				icoColor = "#85aadb";
				break;
			case 2:
				icoColor = "#4ea024";
				break;
			case 3:
				icoColor = "#db8708";
				break;
			case 4:
				icoColor = "#841616";
				break;
			case 5:
				icoColor = "#8758e4";
				break;
			default:
				icoColor = "#000000";
		}
		return (
			<div style={{ display: "flex", alignItems: "center", color: icoColor }}>
				<CircleOutlinedIcon />
			</div>
		);
	};
	const columns = [
		{
			field: "current_status",
			headerName: "Status",
			flex: 1,
			minWidth: 70,
			maxWidth: 70,
			sortable: true,
			filterable: true,
			renderCell: (params) => {
				return getStatusText(params.row.current_status);
			},
		},

		{
			field: "book_full_name",
			hideable: false,
			headerName: "Owners Full Name",
			flex: 1,
			minWidth: 190,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "book_added",
			hideable: false,
			headerName: "Request Date",
			flex: 1,
			minWidth: 150,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{formatDate(cellValues.value)}
					</div>
				);
			},
		},
		{
			field: "book_phone",
			hideable: false,
			headerName: "Contact Number",
			flex: 1,
			minWidth: 180,
			maxWidth: 180,
			sortable: false,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "book_add_1",
			headerName: "Address",
			flex: 1,
			sortable: true,
			minWidth: 240,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "book_city",
			headerName: "City",
			flex: 1,
			minWidth: 110,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "book_prov",
			headerName: "Prov.",
			flex: 1,
			sortable: false,
			minWidth: 80,
			maxWidth: 80,
			type: "text",
			headerAlign: "right",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "center",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "contractor_full_name",
			headerName: "Request Owner",
			flex: 1,
			minWidth: 190,
			sortable: true,
			type: "text",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "left",
							padding: "5px 5px",
						}}
					>
						{cellValues.value}
					</div>
				);
			},
		},
		{
			field: "edit",
			hideable: false,
			headerName: "Edit",
			flex: 1,
			sortable: false,
			minWidth: 80,
			maxWidth: 80,
			type: "text",
			headerAlign: "right",
			renderCell: (cellValues) => {
				return (
					<div
						style={{
							width: "100%",
							textAlign: "right",
							fontSize: "12px",
							paddingRight: "10px",
						}}
					>
						<Button
							onClick={() => {
								// Set the selectedRowData with the data from the row
								setSelectedRowData(cellValues.row);
								// Open the modal
								setOpen(true);
							}}
						>
							Edit
						</Button>
					</div>
				);
			},
		},
	];
	const refreshData = async () => {
		try {
			const response = await axios.get(
				"https://nrgwappdata.com/bookingsContact"
			);
			setBookings(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	return (
		<div className="bookings">
			{open && (
				<Modal
					open={open}
					onClose={() => {
						setOpen(false);
						refreshData();
					}}
					bookingRequest={selectedRowData}
				/>
			)}
			<div className="bookingsContainer">
				<div className="allBookings">
					<Box>
						<DataGridPro
							autoHeight
							rows={bookings}
							columns={columns}
							getRowId={(row) => row.book_id}
							slots={{
								noRowsOverlay: CustomNoRowsOverlay,
								noResultsOverlay: CustomNoRowsOverlay,
								toolbar: GridToolbar,
							}}
							sx={{ "--DataGrid-overlayHeight": "300px" }}
							pageSizeOptions={[5, 10, 15, 25, 100]}
							pageSize={5}
							disableColumnFilter
							disableColumnSelector
							disableDensitySelector
							slotProps={{
								toolbar: {
									showQuickFilter: true,
								},
							}}
							initialState={{
								columns: {
									columnVisibilityModel: {
										book_city: false,
										book_prov: false,
									},
								},
								filter: {
									filterModel: {
										items: [],
										quickFilterValues: [""],
										quickFilterExcludeHiddenColumns: true,
									},
								},
							}}
						/>
					</Box>
				</div>
			</div>
		</div>
	);
};

export default NotBooked;
