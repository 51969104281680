import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";

import React, { useState, useContext, useEffect } from "react";
import ReactModal from "react-modal";
import Login from "./pages/login/Login";
import Users from "./pages/users/Users";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import DashBoard from "./pages/dashboard/DashBoard";
import Analytics from "./pages/analytics/Analytics";
import Bookings from "./pages/bookings/Bookings";

import Logout from "./pages/logout/Logout";
import Messaging from "./pages/messaging/Messaging";
import Settings from "./pages/settings/OfficeSettings";
import Profile from "./pages/users/Profile";
import Register from "./pages/register/Register";
import MyDashboard from "./pages/dashboard/MyDashboard";
import { MyMessaging } from "./components/myMessaging/MyMessaging";
import NotAuthorized from "./pages/errorPages/NotAuthorized";
import NotFound from "./pages/errorPages/NotFound";
import { MySettings } from "./components/mySettings/MySettings";

import NewBooking from "./pages/bookings/AddBooking";
import Needed from "./components/bookings/NeedContact";

import { AuthContext, AuthProvider } from "./context/AuthProvider";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import ProtectedRoute from "./ProtectedRoute";
import { useNavigate } from "react-router-dom";

import ModEditUser from "./components/modals/AddUser";
import BookingsUncontacted from "./pages/bookings/Contact";
import BookingsContacted from "./pages/bookings/Contacted";
import BookingsFailed from "./pages/bookings/Cancelled";

function App() {
	const [openModal, setOpenModal] = useState(false);
	// const navigate = useNavigate();
	useEffect(() => {
		ReactModal.setAppElement("#root");
	}, []);

	return (
		<AuthProvider>
			<BrowserRouter>
				<div className="App">
					<ToastContainer className="Toastify__toast-container--center" />
					<Routes>
						<Route index element={<Login />} />
						{/* <Route path="/login" element={<Login />} /> */}
						<Route path="/not-authorized" element={<NotAuthorized />} />
						<Route
							path="/dashboard"
							element={
								<ProtectedRoute allowedRoles={[1900, 2382, 3001]}>
									<DashBoard />
								</ProtectedRoute>
							}
						/>
						<Route path="/mydashboard" element={<MyDashboard />} />
						<Route path="/mydashboard/mymessages" element={<MyMessaging />} />
						<Route path="/mydashboard/mysettings" element={<MySettings />} />
						<Route path="/mydashboard/newbooking" element={<NewBooking />} />

						<Route path="/users">
							<Route index element={<Users />} />
							<Route path="adduser" element={<AddUser />} />
							<Route path=":editID" element={<EditUser />} />
						</Route>

						<Route path="/analytics" element={<Analytics />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/needcontact" element={<Needed />} />

						<Route path="/requests" element={<Bookings />} />

						<Route path="/logout" element={<Logout />} />
						<Route path="/messaging" element={<Messaging />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/register" element={<Register />} />

						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
