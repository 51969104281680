import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import Featured from "../../components/chart/featured";
import Chart from "../../components/chart";

import "./Analytics.scss";

const Analytics = () => {
	return (
		<div className="analytics">
			<Sidebar />

			<div className="analyticsContainer">
				<h3>Analytics</h3>
				<div className="analyticsBody">
					<Chart />
					<Featured />
				</div>
			</div>
		</div>
	);
};
export default Analytics;
