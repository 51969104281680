import React, { useState, useEffect } from "react";
import "./modal.scss";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const ModalGeneric = ({ open, onClose, bookingRequest }) => {
	const navigate = useNavigate();

	const closeModal = () => {
		setIsModalOpen(false);
		navigate("/requests"); // Replace "/your-target-route" with the desired route
	};

	const initialFormState = {
		out_result: "",
		out_comments: "",
		out_by: bookingRequest.book_owner,
		book_id: bookingRequest.book_id,
		current_status: bookingRequest.current_status,
	};
	const [data, setData] = useState([]);
	const columns = [
		{
			field: "out_date",
			headerName: "Date",
			width: 270,
			renderCell: (params) => (
				<span>{convertEpochToReadable(params.value)}</span>
			),
		},
		{
			field: "out_result",
			headerName: "Result",
			width: 80,
			renderCell: (params) => (
				<div style={{ textAlign: "center", width: "100%" }}>{params.value}</div>
			),
		},
		{ field: "out_by", headerName: "Agent", width: 200 },
		{ field: "out_comments", headerName: "Comments", width: 340 },
	];

	const [gridData, setGridData] = useState([]); // State to hold DataGrid data

	// Fetch initial data for the DataGrid
	useEffect(() => {
		fetchBookingEntries(bookingRequest.book_id);
	}, []);

	const fetchBookingEntries = async (bookId) => {
		try {
			const response = await axios.get(
				`https://nrgwappdata.com/commEntries?book_id=${bookingRequest.book_id}`
			);
			setGridData(response.data);
			setData(response.data);
		} catch (error) {
			console.error("Error fetching booking entries:", error);
		}
	};

	const showSuccessToast = () => {
		toast.success(
			"New communication entry \nhas been successfully added \nto the database.",
			{
				position: "top-center", // set position to top-center
				autoClose: 1500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				onClose: () => {
					setIsModalOpen(false); // Close the modal after the toast message
					//window.location.reload();
					//fetchBookingEntries(bookingRequest.book_id); // Fetch updated data and refresh the list
					//refreshData()
				},
			}
		);
	};

	const showErrorToast = () => {
		toast.error(
			"Cannot connect to database. Please ensure you have internet connectivity, and try again.",
			{
				position: "top-center", // set position to top-center
				autoClose: 3500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				width: 450,
				onClose: () => {
					setIsModalOpen(false); // Close the modal after the toast message
				},
			}
		);
	};
	const [isModalOpen, setIsModalOpen] = useState(open);

	const [formValues, setFormValues] = useState(initialFormState);

	const handleChange = (event) => {
		setFormValues({
			...formValues,
			[event.target.name]: event.target.value,
		});
	};

	useEffect(() => {}, [gridData]);
	useEffect(() => {
		setIsModalOpen(open);
	}, [open]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		console.log("Submitting form with values:", formValues);

		try {
			const response = await fetch("https://nrgwappdata.com/bookCommAdd", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formValues),
			});
			const data = await response.json();
			// handle response data
			showSuccessToast();
			setIsModalOpen(false);

			// Fetch updated data and refresh the list
			fetchBookingEntries(bookingRequest.book_id);
		} catch (error) {
			console.error("Error submitting the form:", error);
			showErrorToast();
		}
	};

	const replacements = {
		A: "Air Source Heat Pump",
		B: "Domestic Hot Water ASHP",
		C: "Windows/Doors",
		D: "Insulation",
		E: "Solar",
		F: "Other",
	};

	const convertEpochToReadable = (epoch) => {
		const date = new Date(epoch);
		return date.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		});
	};

	const getStatusText = (status) => {
		let icoColor;
		let statusText;

		switch (status) {
			case 1:
				icoColor = "#85aadb";
				statusText = "No Answer, Left Voice Message.";
				break;
			case 2:
				icoColor = "#4ea024";
				statusText = "Request Has Been Booked";
				break;
			case 3:
				icoColor = "#db8708";
				statusText = "Homeowner Is Still Undecided.";
				break;
			case 4:
				icoColor = "#841616";
				statusText = "Homeowner Has Cancelled & No Longer Interested.";
				break;
			case 5:
				icoColor = "#8758e4";
				statusText = "No Answer & No Voice Mail Available To Leave A Message.";
				break;
			default:
				icoColor = "#000000";
				statusText = "Homeowner Has Never Been Contacted.";
		}

		return (
			<div style={{ display: "flex", alignItems: "center", color: icoColor }}>
				<CircleOutlinedIcon />
				<span style={{ marginLeft: "8px", color: "#000000" }}>
					{statusText}
				</span>
			</div>
		);
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	if (!isModalOpen) return null;

	return (
		<div className="overlay" onClick={onClose}>
			<div
				onClick={(e) => {
					e.stopPropagation();
				}}
				className="modalContainer"
			>
				<div className="closeBtn">
					<p onClick={onClose}>X</p>
				</div>
				<div className="copy">
					<table
						style={{
							width: "100%",
							padding: "5px",
							fontSize: "13pt",
						}}
					>
						<tbody>
							<tr>
								<td
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between", // Adjusts space distribution
										width: "100%", // Adjusts the width to take the full row
									}}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										{getStatusText(bookingRequest.current_status)}
									</div>
									<span>
										Number of Attempts Made to Book:{" "}
										{bookingRequest.book_attempts}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="detailsContainer">
						<div className="bookingDetails">
							<h3>Booking Overview</h3>
							<table
								style={{
									fontSize: "12pt",
									backgroundColor: "#FFFFFF",
								}}
							>
								<thead></thead>
								<tbody>
									<tr>
										<td
											colSpan="2"
											style={{
												width: "7%",
												backgroundColor: "#c0c0c0",
												color: "#e5e0e0",
											}}
										>
											&nbsp;ID: {bookingRequest.book_id}
										</td>
										<td
											colSpan="2"
											style={{
												width: "17%",
												textAlign: "right",
												backgroundColor: "#b0e094",
											}}
										>
											Booking Request By: &nbsp;
										</td>
										<td
											colSpan="2"
											style={{
												width: "70%",
												textAlign: "left",
												backgroundColor: "#b0e094",
											}}
										>
											<table style={{ width: "100%" }}>
												<thead></thead>
												<tbody>
													<tr>
														<td
															style={{
																width: "23%",
																textAlign: "left",
															}}
														>
															{bookingRequest.contractor_full_name}
														</td>
														<td style={{ width: "25%", textAlign: "right" }}>
															Phone: {bookingRequest.cell}
														</td>
														<td style={{ width: "9%", textAlign: "right" }}>
															Email:
														</td>
														<td style={{ width: "43%", textAlign: "left" }}>
															{bookingRequest.email}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
									<tr>
										<td colSpan="6">
											<table
												style={{ width: "100%", backgroundColor: "#b0e094" }}
											>
												<thead></thead>
												<tbody>
													<tr>
														<td style={{ width: "20%", textAlign: "left" }}>
															Preferred Energy Advisor:
														</td>
														<td style={{ width: "25%", textAlign: "left" }}>
															{bookingRequest.prefer_agent_1}
														</td>
														<td style={{ width: "21%", textAlign: "right" }}>
															Preferred Energy Advisor: &nbsp;
														</td>
														<td style={{ width: "33%", textAlign: "left" }}>
															{" "}
															&nbsp; &nbsp;
															{bookingRequest.prefer_agent_2}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="clientData">
							<table
								style={{
									borderColor: "#000",
									borderWidth: "1px",
									borderStyle: "solid",
									fontSize: "12pt",
									backgroundColor: "#FFFFFF",
								}}
							>
								<thead></thead>
								<tbody>
									<tr>
										<td colSpan="6">
											<h3>Client Data</h3>
										</td>
									</tr>
									<tr>
										<td>
											<table
												style={{
													fontSize: "13pt",
													backgroundColor: "#FFFFFF",
												}}
											>
												<thead></thead>
												<tbody>
													<tr style={{ fontWeight: "800" }}>
														<td style={{ width: "18%", textAlign: "left" }}>
															&nbsp;Home Owner Name:
														</td>
														<td style={{ textAlign: "left", width: "24%" }}>
															{bookingRequest.book_full_name}
														</td>
														<td style={{ width: "18%", textAlign: "left" }}>
															Contact Number:
														</td>
														<td style={{ width: "16%" }}>
															{bookingRequest.book_phone}
														</td>
														<td style={{ width: "10%", textAlign: "right" }}>
															Alt Phone:
														</td>
														<td style={{ width: "16%", textAlign: "right" }}>
															{bookingRequest.book_alt_phone} &nbsp;
														</td>
													</tr>
													<tr>
														<td colSpan="6">
															<table style={{ width: "100%" }}>
																<thead></thead>
																<tbody>
																	<tr>
																		<td style={{ width: "10%" }}>Address:</td>
																		<td
																			colSpan="5"
																			style={{
																				textAlign: "left",
																				width: "90%",
																			}}
																		>
																			{bookingRequest.book_add_1} &nbsp; &nbsp;{" "}
																			{bookingRequest.book_add_2}
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
													<tr>
														<td colSpan="6">
															<table style={{ width: "100%" }}>
																<thead></thead>
																<tbody>
																	<tr>
																		<td
																			style={{ width: "5%", textAlign: "left" }}
																		>
																			City:
																		</td>
																		<td
																			style={{
																				width: "20%",
																				textAlign: "left",
																			}}
																		>
																			{bookingRequest.book_city}
																		</td>
																		<td
																			style={{
																				width: "10%",
																				textAlign: "right",
																			}}
																		>
																			Prov:
																		</td>
																		<td
																			style={{
																				width: "10%",
																				textAlign: "left",
																			}}
																		>
																			{bookingRequest.book_prov}
																		</td>
																		<td
																			style={{
																				width: "10%",
																				textAlign: "right",
																			}}
																		>
																			Email:
																		</td>
																		<td
																			style={{
																				width: "45%",
																				textAlign: "left",
																			}}
																		>
																			{bookingRequest.book_email}
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
									<tr>
										<td colSpan="6">
											&nbsp; Planned Upgrade(s):{" "}
											{bookingRequest.book_planned
												? bookingRequest.book_planned
														.split(",")
														.map((id) => replacements[id.trim()] || id.trim())
														.join(", ")
												: "None specified"}
											<br />
											&nbsp;
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<div className="newBookingAttempt">
								<h3>New Communication Entry</h3>

								<form
									onSubmit={handleSubmit}
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										gap: "10px",
										padding: "10px",
									}}
								>
									<div style={{ display: "flex", gap: "10px", width: "100%" }}>
										<FormControl size="small" style={{ flex: 1 }}>
											<Select
												name="out_result"
												value={formValues.out_result}
												onChange={handleChange}
											>
												<MenuItem value="">Select an option</MenuItem>
												<MenuItem value="5">No answer & no voice mail</MenuItem>
												<MenuItem value="1">No answer left message</MenuItem>
												<MenuItem value="2">Booked</MenuItem>
												<MenuItem value="3">Homeowner undecided</MenuItem>
												<MenuItem value="4">Homeowner cancelled</MenuItem>
											</Select>
										</FormControl>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "column",
											width: "100%",
											gap: "10px",
										}}
									>
										<TextField
											name="out_comments"
											value={formValues.out_comments}
											onChange={handleChange}
											multiline
											fullWidth
											rows={2}
											variant="outlined"
											size="small"
										/>

										<input
											type="hidden"
											name="out_by"
											value={formValues.out_by}
										/>
										<input
											type="hidden"
											name="book_id"
											value={formValues.book_id}
										/>

										<button
											type="submit"
											style={{ alignSelf: "flex-end" }}
											disabled={
												!formValues.out_result ||
												formValues.out_result === "default"
											}
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</LocalizationProvider>
						<div className="commHistory">
							<h3>Communication History</h3>

							<DataGridPro
								rows={gridData}
								columns={columns}
								getRowId={(row) => row.out_id}
								sx={{
									"& .MuiDataGrid-cell": {
										fontSize: "14px",
									},
								}}
								pageSize={5}
								rowsPerPageOptions={[5]}
								checkboxSelection={false}
								style={{ minHeight: "150px" }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalGeneric;
