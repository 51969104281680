import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material"; // Import Box from MUI

export function SortableItem({ id, name }) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		// Additional styles for better visual feedback
		padding: "10px",
		border: "1px solid #ccc",
		marginBottom: "5px",
		backgroundColor: "white",
		cursor: "grab",
	};

	return (
		<Box
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			sx={{
				padding: 1,
				backgroundColor: "white",
				borderRadius: "4px",
				boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
				// Ensuring the component is visually draggable
				cursor: "grab",
			}}
		>
			{name}
		</Box>
	);
}

export function arrayMove(array, from, to) {
	const item = array[from];
	const length = array.length;
	const diff = from - to;

	if (diff > 0) {
		// Moving forwards
		return [
			...array.slice(0, to),
			item,
			...array.slice(to, from),
			...array.slice(from + 1, length),
		];
	} else if (diff < 0) {
		// Moving backwards
		return [
			...array.slice(0, from),
			...array.slice(from + 1, to + 1),
			item,
			...array.slice(to + 1, length),
		];
	}
	return array;
}
