import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../context/AuthProvider"; // Make sure this path is correct
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import "./Bookings.scss";

const MyBookDash = () => {
	const [parentRecords, setParentRecords] = useState([]);
	const [childRecords, setChildRecords] = useState({});
	const { authState } = useContext(AuthContext);
	const currentUserId = authState.user?.id; // Accessing the user ID
	const [statusText, setStatusText] = useState("View All"); // Default text
	const [noRecordsFound, setNoRecordsFound] = useState(false);
	useEffect(() => {
		const fetchParentRecords = async (userId) => {
			try {
				const response = await axios.get(
					`https://nrgwappdata.com/myBookingsContact/${userId}`
				);
				setParentRecords(response.data);
			} catch (error) {
				console.error("Error fetching parent records:", error);
			}
		};

		if (currentUserId) {
			fetchParentRecords(currentUserId);
		}
	}, [currentUserId]); // Dependency on currentUserId

	const fetchChildRecords = async (parentId) => {
		if (childRecords[parentId]) {
			return;
		}

		try {
			const response = await axios.get(
				`https://nrgwappdata.com/childrenOfRecord/${parentId}`
			);
			setChildRecords((prevRecords) => ({
				...prevRecords,
				[parentId]: response.data.length > 0 ? response.data : null,
			}));
		} catch (error) {
			console.error("Error fetching child records:", error);
		}
	};
	const handleAccordionChange = (parentId) => (event, isExpanded) => {
		//	console.log("IN THE ACCORDION");
		if (isExpanded) {
			fetchChildRecords(parentId);
		}
	};
	const getStatusText = (status) => {
		let icoColor;
		let statusText;
		//	console.log(status);

		switch (status) {
			case 1:
				icoColor = "#85aadb";
				statusText = "No Answer, Left Message.";
				break;
			case 2:
				icoColor = "#4ea024";
				statusText = "Request Booked";
				break;
			case 3:
				icoColor = "#db8708";
				statusText = "Homeowner Undecided.";
				break;
			case 4:
				icoColor = "#841616";
				statusText = "Homeowner Cancelled";
				break;
			case 5:
				icoColor = "#8758e4";
				statusText = "No Answer & No Voice Mail.";
				break;
			default:
				icoColor = "#000000";
				statusText = "Homeowner Not Contacted.";
		}

		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					color: icoColor,
				}}
			>
				<CircleOutlinedIcon style={{ fontSize: "10pt" }} />
				<span style={{ marginLeft: "8px", color: "#000000" }}>
					{statusText}
				</span>
			</div>
		);
	};

	const handleStatusClick = async (status) => {
		let newText;
		switch (status) {
			case 0:
				newText = "Not Yet Contacted";
				break;
			case 1:
				newText = "Contacted, Left Message";
				break;
			case 2:
				newText = "Booked";
				break;

			case 3:
				newText = "Homeowner Undecided";
				break;

			case 4:
				newText = "Homeowner Cancelled";
				break;

			case 10:
				newText = "View All";
				break;
			default:
				newText = "Unknown";
		}
		setStatusText(newText);
		try {
			const response = await axios.get(
				`https://nrgwappdata.com/myBookingWithStatus/${currentUserId}/${status}`
			);
			if (response.data.length === 0) {
				setNoRecordsFound(true);
				setParentRecords([]); // Clear existing records
			} else {
				setNoRecordsFound(false);
				setParentRecords(response.data); // Update with new data
			}
		} catch (error) {
			console.error("Error fetching records for status", status, ":", error);
		}
	};

	const formatEpochDate = (epoch) => {
		const date = new Date(epoch); // Create a Date object from the epoch date
		const options = {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		};
		return date.toLocaleString(undefined, options); // Format the date and time
	};
	const getStatus = (status) => {
		let icoColor;
		let lblStatus;
		switch (status) {
			case 1:
				icoColor = "#85aadb";
				lblStatus = "Msg Left";
				break;
			case 2:
				icoColor = "#4ea024";
				lblStatus = "Booked";
				break;
			case 3:
				icoColor = "#db8708";
				lblStatus = "Undecided";
				break;
			case 4:
				icoColor = "#841616";
				lblStatus = "Cancelled";
				break;
			case 5:
				icoColor = "#8758e4";
				lblStatus = "Requests";
				break;
			default:
				icoColor = "#000000";
				lblStatus = "Requests";
		}

		return (
			<div
				className="statusOption"
				onClick={() => handleStatusClick(status)}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					color: icoColor,
					justifyContent: "center",
				}}
			>
				<CircleOutlinedIcon />
				<span style={{ fontSize: "7.5pt", paddingTop: "3px" }}>
					{lblStatus}
				</span>
			</div>
		);
	};
	const getStatusTextONLY = (status) => {
		let statusText;
		switch (status) {
			case 1:
				statusText = "No Answer, Left Message.";
				break;
			case 2:
				statusText = "Request Booked";
				break;
			case 3:
				statusText = "Homeowner Undecided.";
				break;
			case 4:
				statusText = "Homeowner Cancelled";
				break;
			case 5:
				statusText = "No Answer & No Voice Mail.";
				break;
			default:
				statusText = "Homeowner Not Contacted.";
		}

		return (
			<span style={{ marginLeft: "8px", color: "#000000" }}>{statusText}</span>
		);
	};
	return (
		<div className="requestsBookingsContainer">
			<div className="headerCopy">
				My Bookings -{" "}
				<span className="bookingStatusText">&nbsp;{statusText}</span>
			</div>
			<div className="bookingOptions">
				{getStatus(0)}
				{getStatus(1)}
				{getStatus(2)}
				{getStatus(3)}
				{getStatus(4)}
				<div onClick={() => handleStatusClick(10)}>View All</div>
			</div>
			{parentRecords.length > 0 ? (
				parentRecords.map((record) => (
					<Accordion
						style={{ width: "100%" }}
						className="fullWidthAccordion"
						key={`parent-${record.book_id}`}
						onChange={handleAccordionChange(record.book_id)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel-content-${record.book_id}`}
							id={`panel-header-${record.book_id}`}
						>
							<Typography className="typoFont" component={"span"}>
								{getStatusText(record.current_status)}
								<div>Name: {record.book_full_name}</div>
								<div>Email: {record.book_email}</div>
								<div>Phone: {record.book_phone}</div>
								<div>Alt Phone: {record.book_alt_phone}</div>
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ width: "97%", padding: "5px" }}>
							{childRecords[record.book_id] ? (
								childRecords[record.book_id].map((child) => (
									<Typography
										key={`child-${child.out_id}`}
										className="typoFont childRecord"
										style={{ padding: "10px" }}
										component={"span"}
									>
										<div>Date: {formatEpochDate(child.out_date)}</div>
										<div>Comments - {child.out_comments}</div>
										<div>Result - {getStatusTextONLY(child.out_result)}</div>
									</Typography>
								))
							) : (
								<Typography className="typoFont" component={"span"}>
									<div className="noRecords">
										No communication records found
									</div>
								</Typography>
							)}
						</AccordionDetails>
					</Accordion>
				))
			) : (
				<Typography
					className="typoFont"
					style={{ color: "red" }}
					component={"span"}
				>
					No booking requests found
				</Typography>
			)}
		</div>
	);
};

export default MyBookDash;
