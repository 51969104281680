import React, { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import BookOnlineOutlinedIcon from "@mui/icons-material/BookOnlineOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

import { AuthContext, AuthProvider } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";

import "./Dashboards.scss";

const MyNavbar = () => {
	const { logout } = useContext(AuthContext);
	const navigate = useNavigate();

	const handleLogout = () => {
		logout(); // Call the logout function to clear auth state
		navigate("/"); // Redirect to the login page
	};

	// Add navigation for other icons
	const navigateToDashboard = () => navigate("/mydashboard");
	const navigateToBooking = () => navigate("/mydashboard/newbooking");
	const navigateToMessages = () => navigate("/mydashboard/mymessages");
	const navigateToSettings = () => navigate("/mydashboard/mysettings");

	return (
		<div className="myNavbar">
			<Tooltip title="Dashboard">
				<IconButton className="myDashIconButton" onClick={navigateToDashboard}>
					<HomeOutlinedIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Create new booking">
				<IconButton className="myDashIconButton" onClick={navigateToBooking}>
					<BookOnlineOutlinedIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Messaging">
				<IconButton className="myDashIconButton" onClick={navigateToMessages}>
					<MessageOutlinedIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Settings">
				<IconButton className="myDashIconButton" onClick={navigateToSettings}>
					<SettingsOutlinedIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Logout">
				<IconButton className="myDashIconButton" onClick={handleLogout}>
					<ExitToAppOutlinedIcon />
				</IconButton>
			</Tooltip>
		</div>
	);
};
export default MyNavbar;
