import React, { useState } from "react";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/NavBar";
import { Tabs, Tab, Box } from "@mui/material";
import DeletedMessages from "../../components/messaging/DeleteMessages";

import DeletedUsers from "../../components/deleted_users/DeletedUsers";
import ComOfficeSettings from "../../components/office_settings/ComOfficeSettings";
import "./OfficeSettings.scss";

const OfficeSettings = () => {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<div className="settings">
			<Sidebar />

			<div className="settingsContainer">
				{/* <Navbar /> */}
				<Box sx={{ width: "100%" }}>
					<Tabs
						value={selectedTab}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						sx={{ marginLeft: "25px" }} // Add left margin
					>
						<Tab label="Application Settings" />
						<Tab label="Deleted User(s)" />
						<Tab label="Deleted Message(s)" />
						{/* <Tab label="Deleted Bookings" /> */}
					</Tabs>
				</Box>
				{selectedTab === 0 && <ComOfficeSettings />}
				{selectedTab === 1 && <DeletedUsers />}
				{selectedTab === 2 && <DeletedMessages />}
			</div>
		</div>
	);
};

export default OfficeSettings;
