// NotAuthorized.js
import React, { useContext } from "react";
import { AuthProvider } from "../../context/AuthProvider";
import { Link } from "react-router-dom";

const NotAuthorized = () => {
	return (
		<div>
			<h1>Not Authorized</h1>
			<p>You do not have permission to view this page.</p>
			<Link to="/">Go back to the homepage</Link>
		</div>
	);
};

export default NotAuthorized;

// import React, { useContext } from "react";
// import { AuthContext } from "../../context/AuthProvider";
// import { Route, Navigate, Link, useLocation } from "react-router-dom";

// const NotAuthorized = () => {
// 	const { authState } = useContext(AuthContext);
// 	const { isAuthenticated, user } = authState;

// 	return (
// 		<Route
// 			{...rest}
// 			render={(props) => {
// 				if (!isAuthenticated) {
// 					// Not logged in, redirect to login page
// 					return <Navigate to="/login" />;
// 				} else if (!userCanAccess(user)) {
// 					// Logged in but not authorized, redirect to Not Authorized page
// 					return <Navigate to="/not-authorized" />;
// 				} else {
// 					// Authorized, render the component
// 					return <Component {...props} />;
// 				}
// 			}}
// 		/>
// 	);
// };

// export default NotAuthorized;
